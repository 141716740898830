import {
    SET_CARD_HOLDER,
    SET_CARD_NUMBER,
    SET_CARD_TYPE,
    SET_CVC,
    SET_EXPIRE_MONTH,
    SET_EXPIRE_YEAR,
    SET_SIGNATURE,
    PaymentAction,
    SET_FORTH_ID,
    RESET,
    SET_TERMS,
} from "$redux/actions/Payment/paymentActionTypes";

export interface IPayment {
    terms: boolean;
}
export interface IPaymentCreditCard extends IPayment {
    cardType: string;
    cardHolder: string;
    cardNumber: string;
    expireMonth: string;
    expireYear: string;
    cvc: string;
    signature: string;
    fortId: string;
}

export const initialState = {
    cardType: "",
    cardHolder: "",
    cardNumber: "",
    expireMonth: "",
    expireYear: "",
    cvc: "",
    signature: "",
    fortId: "",
    terms: false,
} as IPaymentCreditCard;

export const paymentReducer = (state = initialState, action: PaymentAction) => {
    switch (action.type) {
        case SET_CARD_HOLDER:
            return { ...state, cardHolder: action.cardHolder };
        case SET_CARD_NUMBER:
            return { ...state, cardNumber: action.cardNumber };
        case SET_CARD_TYPE:
            return { ...state, cardType: action.cardType };
        case SET_CVC:
            return { ...state, cvc: action.cvc };
        case SET_EXPIRE_MONTH:
            return { ...state, expireMonth: action.expireMonth };
        case SET_EXPIRE_YEAR:
            return { ...state, expireYear: action.expireYear };
        case SET_SIGNATURE:
            return { ...state, signature: action.signature };
        case SET_FORTH_ID:
            return { ...state, forthId: action.forthId };
        case RESET:
            return { ...initialState };
        case SET_TERMS:
            return { ...state, terms: action.terms };
        default:
            return state;
    }
};
