import { SET_NAME, SET_EMAIL, VALIDATION } from "./buyerActionTypes";
import { BUYER } from "../Checkout/checkoutActionTypes";

import { IBuyer } from "$models/Checkout";
import { ErrorType } from "@djordjeandjelkovic/medgress_common_react_modules";

export const setName = (name: string) => ({
    type: SET_NAME,
    altType: BUYER,
    name,
});

export const setEmail = (email: string) => ({
    type: SET_EMAIL,
    altType: BUYER,
    email,
});

export const setValidationBuyer = (buyer: ErrorType<IBuyer>) => ({
    type: VALIDATION,
    altType: BUYER,
    buyer,
});
