import React from "react";

import { useStyles } from "./styles";

import { NavLink } from "react-router-dom";

import { MenuItem } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";

interface IUserProfileMenuItemProps {
    onClick?: () => void;
}
const UserProfileMenuItem: React.FC<IUserProfileMenuItemProps> = ({ onClick }) => {
    const classes = useStyles({});
    
    return (
        <MenuItem component={NavLink} to={`/profile`} onClick={onClick}>
            <PersonIcon className={classes.icon} />
            User profile
        </MenuItem>
    );
};

export { UserProfileMenuItem };
