import * as api from "$helper/api";
import { apiPrefix } from "../serviceConstants";

import { Company } from "@djordjeandjelkovic/medgress_common_react_modules";

export const getByAcronym = (acronym: string): Promise<Company> => {
    return api.get<Company>(`${apiPrefix.companies}/acronym/${acronym}`);
};

export default getByAcronym;
