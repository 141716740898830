import { Theme, makeStyles, createStyles } from "@material-ui/core";

type Props = {
    media?: boolean;
};

export const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        textAlign: "center",
        padding: (props: Props) => (props.media ? 25 : 150),
    },
    header: {
        paddingBottom: 15,
        color: theme.palette.primary.main
    },
    description: {
        padding: 15
    },
    button: {
        marginTop: (props: Props) => (props.media ? 15 : 0),
    },
}));
