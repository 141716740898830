import en from "./language.en";
import ae from "./language.ae";

const locales = {
    en: en,
    ae: ae
};

const getLiterals = (language: string) => {
    if(language === "en") {
        return locales.en;
    }
        
    if(language === "ae") {
        return locales.ae;
    }

    return locales.en;
}

export { getLiterals };
