import { TicketCart } from "$models/types";
import { ATTENDEE, AttendeeAction } from "$redux/actions/AttendeeFields";
import { CART, CartAction } from "$redux/actions/Cart/cartActionsTypes";
import { TICKET, TicketAction } from "$redux/actions/TicketsCart";
import { attendeeFieldsReducer } from "./attendeeFieldsReducer";
import { cartReducer } from "./cartReducer";
import { ticketsReducer } from "./ticketsReducer";

type Action = TicketAction | AttendeeAction | CartAction;
export type TicketsState = {
    [eventId: string]: {
        cartId: string | null;
        tickets: TicketCart[];
    };
}; 

export const ticketsCartMainReducer = (state: TicketsState = {}, action: Action): TicketsState => {
    switch (action.altType) {
        case ATTENDEE:
            return attendeeFieldsReducer(state, action);
        case TICKET:
            return ticketsReducer(state, action);
        case CART:
            return cartReducer(state, action);
        default:
            return state;
    }
};
