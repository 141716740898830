import React from "react";
import { useParams } from "react-router-dom";
import { Large } from "./Large";
import { LargeNoHeader } from "./LargeNoHeader";
import { Medium } from "./Medium";
import { Small } from "./Small";

export const EmbededEventInformation = () => {
    const { size } = useParams<{ size: string }>();

    switch (size) {
        case "large":
            return <Large />;
        case "large-no-header":
            return <LargeNoHeader />;
        case "medium":
            return <Medium />;
        case "small":
            return <Small />;
        default:
            return null;
    }
};
