import { useHistory } from "react-router-dom";
import { useMessage } from "$helper/useMessage";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { EventDTO, TicketDTO } from "@djordjeandjelkovic/medgress_common_react_modules";
import { TicketCart } from "$models/types";
import { useCreateTickets } from "./useCreateTickets";
import { useTicketsActionCreators } from "./useTicketsActionCreators";
import { TicketsCount } from "./useTicketsCount";

export interface ITicketCount {
    ticket: TicketDTO;
    count: number;
}

type AddTicketsProps = {
    ticketsCount: TicketsCount;
    event: EventDTO;
};

export const useAddTickets = ({ ticketsCount: ticketTypes, event }: AddTicketsProps) => {
    const history = useHistory();
    const { showSuccess } = useMessage();
    const getLiteral = useLiterals();
    const { addTicketsOfType: addTicketsOfTypeRedux, addTickets: addTicketsRedux } = useTicketsActionCreators(
        {
            event,
        }
    );
    const { createTickets, createTicketOfType } = useCreateTickets({ ticketTypes, event });

    const addTickets = async () => {
        const ticketsForCart: TicketCart[] = await createTickets();

        const hasTickets = ticketsForCart.length > 0;
        if (!hasTickets) {
            return;
        }

        addTicketsRedux(ticketsForCart);

        showSuccess(getLiteral("event_details.ticket.success_add"));

        const eventId = _getEvent(ticketsForCart);
        history.push(`/checkout/event/${eventId}`);
    };

    const addTicket = async (id: string) => {
        const ticketsForCart: TicketCart[] = await createTicketOfType(id);

        const hasTickets = ticketsForCart.length > 0;
        if (!hasTickets) {
            return;
        }

        addTicketsOfTypeRedux(ticketsForCart);

        showSuccess(getLiteral("event_details.ticket.success_add"));
    };

    return { addTickets, addTicket };
};

const _getEvent = (ticketsForCart: TicketCart[]) => {
    return ticketsForCart[0].event.id;
};
