import React from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { Subscribe, PageHeader, TopBorderContainer } from "$components/core";
import clsx from "clsx";

import { useStyles } from "./styles";

const TermsAndConditions = () => {
    const classes = useStyles();
    const literals = useLiterals();
    const isLowerThenSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    return (
        <>
            <PageHeader title={literals("terms_and_conditions.title")} />
            <Container>
                <TopBorderContainer
                    className={clsx(classes.descriptionContainer, {
                        [classes.descriptionContainerPadding]: !isLowerThenSmall,
                    })}>
                    <Grid container>
                        <Grid item lg={6} className={classes.descriptionPart}>
                            <Typography variant="body1" className={classes.paragraph}>
                                {literals("terms_and_conditions.description1")}
                            </Typography>

                            <Typography variant="body1" className={classes.paragraph}>
                                {literals("terms_and_conditions.description2")}
                            </Typography>

                            <Typography variant="body1" className={classes.paragraph}>
                                {literals("terms_and_conditions.description3")}
                            </Typography>

                            <Typography variant="body1" className={classes.paragraph}>
                                {literals("terms_and_conditions.description4")}
                            </Typography>

                            <Typography variant="body1" className={classes.paragraph}>
                                {literals("terms_and_conditions.description5")}
                            </Typography>
                        </Grid>
                        <Grid item lg={6} className={classes.descriptionPart}>
                            <Typography variant="body1" className={classes.paragraph}>
                                {literals("terms_and_conditions.description6")}
                            </Typography>

                            <Typography variant="body1" className={classes.paragraph}>
                                {literals("terms_and_conditions.description7")}
                            </Typography>

                            <Typography variant="body1" className={classes.paragraph}>
                                {literals("terms_and_conditions.description8")}
                            </Typography>
                        </Grid>
                    </Grid>
                </TopBorderContainer>
            </Container>
            <Subscribe />
        </>
    );
};

export default TermsAndConditions;
