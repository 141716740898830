import React, { useRef } from "react";
import * as config from "$config/config";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import { useStyles } from "../styles";
import { usePayFortPayment } from "./usePayFortPayment";
import { usePaymentSelectors } from "$redux/selectors/usePaymentSelectors";
import PaymentIcon from "@material-ui/icons/Payment";
import { Redirect } from "react-router-dom";
import { SendTicketsTo } from "@djordjeandjelkovic/medgress_common_react_modules";
import { useMessage } from "$helper/useMessage";
import { useLiterals } from "$hooks/Translate/useLiterals";

type PayFortProps = {
    eventId: string;
    cartId: string;
    ticketsTo: SendTicketsTo;
};

const PayFortPayment = ({ eventId, ticketsTo }: PayFortProps) => {
    const classes = useStyles();
    const [isLoading, setLoading] = React.useState(false);
    const { isSuccessPayment } = usePayFortPayment(eventId, ticketsTo);

    if (isSuccessPayment) {
        return <Redirect to={{ pathname: "/success-payment", state: { eventId } }} />;
    }

    return (
        <Grid container className={classes.payment}>
            <Grid item xs={12}>
                <PayFortForm
                    eventId={eventId}
                    isLoading={isLoading}
                    onLoading={setLoading}
                    ticketsTo={ticketsTo}
                />
            </Grid>
        </Grid>
    );
};

type PayFortFormProps = {
    eventId: string;
    isLoading: boolean;
    onLoading: (value: boolean) => void;
    ticketsTo: SendTicketsTo;
};

const PayFortForm = ({ eventId, isLoading, onLoading, ticketsTo }: PayFortFormProps) => {
    const classes = useStyles();
    const { total, totalWithDecimalPoint, currency, signature, returnUrl, merchantExtra, email, reference } =
        usePayFortPayment(eventId, ticketsTo);
    const formRef = useRef<HTMLFormElement>(null);
    const { showError } = useMessage();
    const literals = useLiterals();

    const { payment } = usePaymentSelectors();

    const handleSubmit = () => {
        onLoading(true);
        formRef.current?.submit();
    };

    const handlePay = (e: any) => {
        e.preventDefault();
        if (!payment.terms) {
            showError(literals("ticket_cart.payment.errorTerms"));
            return;
        }
        handleSubmit();
    };

    return (
        <>
            {total !== 0 && (
                <form ref={formRef} action={config.payment.url} method="POST">
                    <input type="hidden" name="command" value="PURCHASE" />
                    <input type="hidden" name="access_code" value={config.payment.accessCode} />
                    <input type="hidden" name="merchant_identifier" value={config.payment.mIdentifier} />
                    <input type="hidden" name="merchant_reference" value={reference} />
                    <input type="hidden" name="language" value="en" />
                    <input type="hidden" name="amount" value={totalWithDecimalPoint} />
                    <input type="hidden" name="currency" value={currency} />
                    <input type="hidden" name="return_url" value={returnUrl} />
                    <input type="hidden" name="merchant_extra" value={merchantExtra} />
                    <input type="hidden" name="customer_email" value={email} />
                    <input type="hidden" name="signature" value={signature} />

                    <Button
                        className={classes.paymentBtn}
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={isLoading || !payment.terms}
                        onClick={handlePay}>
                        {isLoading && <CircularProgress className={classes.paymentProgress} size={24} />}
                        <PaymentIcon />
                        {literals("ticket_cart.payment.btnPay")}
                    </Button>
                </form>
            )}
        </>
    );
};

export { PayFortPayment };
