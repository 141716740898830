import { ICheckout, IBuyer } from "$models/Checkout";
import { BUYER, RESET, CHECKOUT, SET_ACTIVE_STEP } from "$redux/actions/Checkout/checkoutActionTypes";
import { buyerReducer } from "./buyerReducer";
import { ErrorType } from "@djordjeandjelkovic/medgress_common_react_modules";

const initialState: ICheckout = {
    buyerInfo: {
        name: {
            value: "",
            error: false,
            message: "",
        },
        email: {
            value: "",
            error: false,
            message: "",
        },
    } as ErrorType<IBuyer>,
    activeStep: { index: 0 },
} as ICheckout;

export const checkoutMainReducer = (state: ICheckout = initialState, action: any): ICheckout => {
    switch (action.altType) {
        case BUYER:
            return {
                ...state,
                buyerInfo: buyerReducer(state.buyerInfo, action),
            };
        case CHECKOUT:
            return checkoutReducer(state, action);
        default:
            return state;
    }
};

const checkoutReducer = (state: ICheckout, action: any) => {
    switch (action.type) {
        case RESET:
            return reset(state);
        case SET_ACTIVE_STEP:
            return setActiveStep(state, action);
        default:
            return state;
    }
};

const reset = (state: ICheckout) => ({
    ...initialState,
    id: state.id,
});

const setActiveStep = (state: ICheckout, action: any) => ({
    ...state,
    activeStep: action.step,
});
