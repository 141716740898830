import React from "react";
import clsx from "clsx";
import { Redirect } from "react-router";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Loader, PageHeader, TopBorderContainer } from "$components/core";
import { useCheckoutState } from "./useCheckoutState";
import { useStyles } from "./styles";
import { ShowSideTotalGrid } from "./ShowSideTotalGrid";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { PaymentGrid } from "./PaymentGrid";
import { AttendeeStepper } from "./AttendeeStepper";
import { ATTENDEE_INDEX_OFFSET } from "./checkoutHelper";
import { ElapsedCartTimeGrid } from "./ElapsedCartTimeGrid";

const Checkout = () => {
    const [
        {
            eventId,
            cartId,
            activeStep,
            stepperLabels,
            loading,
            redirect,
            eventDetails,
            isAttendeeStep,
            total,
            referenceToCheckoutForm,
            companyId,
        },
        { goBack, goNext, handleRemoveTicket },
    ] = useCheckoutState();
    const getLiteral = useLiterals();

    const StepComponent = activeStep.component || null;
    const index = activeStep.index!;
    const ticketId = activeStep.ticketId;

    const classes = useStyles({ isAttendeeStep });

    if (redirect) {
        return <Redirect to={{ pathname: "/" }} />;
    }

    const isLastStep = activeStep.index === stepperLabels.length - 1;
    const zeroTotal = total === 0;

    const showBackButton =
        (activeStep?.index || 0) > 0 && (activeStep?.index || stepperLabels.length) < stepperLabels.length;

    const showNextButton =
        (typeof activeStep?.index === "undefined" ? stepperLabels.length : activeStep.index) <
        stepperLabels.length - 1;

    return (
        <>
            <PageHeader
                subtitle={eventDetails?.name || ""}
                imgSrc={eventDetails?.coverImage}
                title={getLiteral("checkout.title")}
                removeHeader={true}
            />

            <Container>
                <Grid container spacing={3} ref={referenceToCheckoutForm}>
                    <Grid item md={isLastStep && zeroTotal ? 12 : 7} xs={12}>
                        <TopBorderContainer
                            className={clsx(classes.wrapper, { [classes.wrapperWithTotal]: isAttendeeStep })}
                        >
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <AttendeeStepper
                                            stepperLabels={stepperLabels}
                                            activeStep={activeStep}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {(loading && <Loader loading={true} />) ||
                                            renderComponent(StepComponent, {
                                                eventId,
                                                cartId,
                                                index: toAttendeeIndex(index),
                                                attendeeType: activeStep.attendeeType,
                                            })}
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions className={classes.actions}>
                                {isAttendeeStep && (
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        disabled={loading}
                                        onClick={() => handleRemoveTicket(ticketId!)}
                                    >
                                        <DeleteIcon />
                                        {getLiteral("checkout.action.remove_ticket")}
                                    </Button>
                                )}

                                <div>
                                    {showBackButton && (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={goBack}
                                            disabled={loading}
                                        >
                                            <ArrowBackIosIcon />
                                            {getLiteral("common.button.back")}
                                        </Button>
                                    )}
                                    {showNextButton && (
                                        <Button
                                            className={classes.nextBtn}
                                            color="primary"
                                            variant="contained"
                                            onClick={goNext}
                                            disabled={loading}
                                            endIcon={<ArrowForwardIosIcon />}
                                        >
                                            {getLiteral("common.button.next")}
                                        </Button>
                                    )}
                                </div>
                            </CardActions>
                        </TopBorderContainer>
                    </Grid>
                    <Grid item container md={5} xs={12} className={classes.rightSide}>
                        <ShowSideTotalGrid item xs={12} visible={isAttendeeStep} eventId={eventId} />
                        <ElapsedCartTimeGrid item xs={12} visible={!isLastStep} eventId={eventId} />
                        <PaymentGrid
                            item
                            xs={12}
                            visible={isLastStep && !zeroTotal}
                            eventId={eventId!}
                            cartId={cartId!}
                            companyId={companyId!}
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

const toAttendeeIndex = (index: number) => {
    return index - ATTENDEE_INDEX_OFFSET;
};

const renderComponent = (Component: React.ComponentType<any> | null, props: any) => {
    if (!Component) return undefined;
    return <Component {...props} />;
};

export { Checkout };
