import { useCallback, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { companyService } from "$services/core";
import eventsService from "$services/events.service";
import { Company, EventDTO } from "@djordjeandjelkovic/medgress_common_react_modules";
import { EventFilterDTO } from "$models/EventFilter";
import { filterEvents } from "../../utilities/eventUtils";

const useCompanyEventsState = () => {
    const [list, setList] = useState<EventDTO[]>([]);
    const [company, setCompany] = useState<Company | undefined>(undefined);
    const [isLoading, setLoading] = useState(false);
    const [filteredList, setFilteredList] = useState<EventDTO[] | undefined>(undefined);
    const [filter, setFilter] = useState<EventFilterDTO>(EventFilterDTO.Default());

    const { acronym } = useParams<{ acronym: string }>();

    useEffect(() => {
        setLoading(true);
        const getData = async () => {
            try {
                const companyResponse = await companyService.getByAcronym(acronym);
                if(companyResponse === null) {
                    throw Error(`Invalid company ${acronym}.`);
                }

                setCompany(companyResponse);

                const eventsResponse = await eventsService.listWithExternal(acronym);
                if (eventsResponse === null) {    
                    return;
                }
    
                setList(eventsResponse);
    
                const filteredEventList = filterEvents(
                    eventsResponse,
                    EventFilterDTO.Default()
                );

                if (filteredEventList == null) {
                    return;
                }

                setFilteredList(filteredEventList);
            } catch(error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        getData();
    }, [acronym]);

    const changeEventStartDate = (date: Date) => {
        const eventsFilter = { ...filter };
        eventsFilter.startDate = date;

        setFilter(eventsFilter);
        applyFilter(list, eventsFilter);
    };

    const changeEventType = (category: string) => {
        const eventsFilter = { ...filter };
        eventsFilter.eventType = category;

        setFilter(eventsFilter);
        applyFilter(list, eventsFilter);
    };

    const changeEventName = (name: string) => {
        const eventsFilter = { ...filter };
        eventsFilter.name = name;

        setFilter(eventsFilter);
        applyFilter(list, eventsFilter);
    };

    const changeEventCity = (city: string) => {
        const eventsFilter = { ...filter };
        eventsFilter.city = city;

        setFilter(eventsFilter);
        applyFilter(list, eventsFilter);
    };

    const changeEventNatureType = (location: string) => {
        const eventsFilter = { ...filter };
        eventsFilter.natureType = location;

        setFilter(eventsFilter);
        applyFilter(list, eventsFilter);
    };

    const applyFilter = useCallback((list: EventDTO[] ,filter: EventFilterDTO) => {
        const filteredEvents = filterEvents(list, filter);
        if (filteredEvents == null) {
            return;
        }
        setFilteredList(filteredEvents);
    }, [setFilteredList]);

    const clearFilter = () => {
        setFilter(EventFilterDTO.Default());
        applyFilter(list, EventFilterDTO.Default());
    };    

    return [{list, filteredList, filter, company, isLoading},
    {
        changeEventStartDate, changeEventType, changeEventName, changeEventCity, changeEventNatureType, clearFilter
    }];
}

export { useCompanyEventsState };
