import { Theme, makeStyles, createStyles } from "@material-ui/core";
import images from "$assets/images";
import { PageHeaderProps } from "./PageHeader";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "relative",
            minHeight: 300,
            backgroundRepeat: (props: PageHeaderProps) => (props.imgSrc ? "no-repeat" : undefined),
            backgroundSize: (props: PageHeaderProps) => (props.imgSrc ? "cover" : undefined),
            "&:before": {
                content: '""',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: (props: PageHeaderProps) => (!props.imgSrc ? "rgba(0,0,0,0.7)" : undefined),
                position: "absolute",
            },
            backgroundImage: (props: PageHeaderProps) => `url(${props.imgSrc || images.defaultEventCover})`,
        },
        title: {
            paddingTop: `${theme.typography.pxToRem(70)}`,
            color: theme.palette.primary.contrastText,
            position: "relative",
        },
        eventBanner: {
            width: "100%",
        },
        titleCenter: {
            textAlign: "center",
        },
        subtitle: {
            paddingTop: `${theme.typography.pxToRem(35)}`,
            paddingBottom: `${theme.typography.pxToRem(70)}`,
            color: theme.palette.primary.contrastText,
            position: "relative",
        },
    })
);
