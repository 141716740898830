import { TicketCart } from "$models/types";
import {
    TICKET_ADD,
    TICKET_REMOVE,
    TICKETS_ADD,
    TICKET_REMOVE_ALL,
    TICKET,
    TICKET_ADD_TYPE,
} from "./ticketsCartActionTypes";

export const addTicket = (ticket: TicketCart) => ({
    type: TICKET_ADD,
    altType: TICKET,
    ticket: ticket,
});

export const addTicketsOfType = (tickets: TicketCart[]) => ({
    type: TICKET_ADD_TYPE,
    altType: TICKET,
    tickets,
});

export const removeTicket = (ticket: TicketCart) => ({
    type: TICKET_REMOVE,
    altType: TICKET,
    ticket,
});

export const addTickets = (tickets: TicketCart[]) => ({
    type: TICKETS_ADD,
    altType: TICKET,
    tickets,
});

export const removeAllTickets = (eventId: string) => ({
    type: TICKET_REMOVE_ALL,
    altType: TICKET,
    eventId,
});
