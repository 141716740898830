import * as React from "react";
import { TextField, Grid, Typography, Button, CircularProgress, Divider } from "@material-ui/core";

import { useStyles } from "./styles";
import { useLogin } from "./useLogin";
import { SignUpOrForgotPassword } from "./SignUpOrForgotPassword";

import { useLiterals } from "$hooks/Translate/useLiterals";
import { Redirect } from "react-router-dom";

import GoogleLogin from "react-google-login";

import * as config from "$config/config";

interface Props {
    useRedirect: boolean;
}

const LoginForm = (props: Props) => {
    const classes = useStyles();
    const literals = useLiterals();
    const [
        { isLoading, email, password, returnUrl },
        { handleLogin, handleGoogleResponse, handleGoogleFailureResponse, handleChange, handleEnterKey },
    ] = useLogin(props);

    if (returnUrl !== "") {
        return <Redirect to={{ pathname: returnUrl }} />;
    }

    return (
        <Grid container spacing={2} className={classes.form}>
            <Grid item xs={12}>
                <TextField
                    disabled={isLoading}
                    color="primary"
                    fullWidth
                    label={literals("sign_in.form.email")}
                    variant="outlined"
                    value={email.value}
                    error={email.error != null}
                    onChange={handleChange}
                    name="email"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    disabled={isLoading}
                    color="primary"
                    fullWidth
                    type="password"
                    label={literals("sign_in.form.password")}
                    variant="outlined"
                    value={password.value}
                    error={email.error != null}
                    onChange={handleChange}
                    onKeyDown={handleEnterKey}
                    name="password"
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    className={classes.loginButton}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleLogin}
                    disabled={isLoading}
                    size="large">
                    {literals("sign_in.form.submit")}
                    {isLoading && <CircularProgress className={classes.progress} size={24} />}
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Divider />
                <Typography variant="subtitle1" className={classes.signUpOr}>
                    {literals("sign_in.OR")}
                </Typography>
                <Typography component="div" className={classes.signInGoogleButton}>
                    <GoogleLogin
                        clientId={config.googleLogin.clientId!}
                        buttonText={literals("sign_in.with_google")}
                        onSuccess={(response) => handleGoogleResponse(response)}
                        onFailure={() => handleGoogleFailureResponse}
                        cookiePolicy={"single_host_origin"}
                        isSignedIn={false}
                    />
                </Typography>
                <SignUpOrForgotPassword />
            </Grid>

        </Grid>
    );
};


export { LoginForm };
