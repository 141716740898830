import {
    TICKET_ADD,
    TICKET_REMOVE,
    TICKETS_ADD,
    TICKET_REMOVE_ALL,
    TicketAction,
    TICKET_ADD_TYPE,
} from "$redux/actions";
import { TicketCart } from "$models/types";
import _findIndex from "lodash/findIndex";
import _forEach from "lodash/forEach";
import _sortBy from "lodash/sortBy";
import { TicketsState } from "./ticketsCartReducer";

export const ticketsReducer = (state: TicketsState, action: TicketAction) => {
    switch (action.type) {
        case TICKET_ADD:
            return addTicket(state, action);
        case TICKET_ADD_TYPE:
            return addTicketsOfType(state, action);
        case TICKET_REMOVE:
            return removeTicket(state, action);
        case TICKETS_ADD:
            return addTickets(state, action);
        case TICKET_REMOVE_ALL:
            return removeAllTicketsForEvent(state, action.eventId);
        default:
            return state;
    }
};

const addTickets = (state: TicketsState, { tickets }: { tickets: TicketCart[] }) => {
    const ticketsWithCheckoutIndex = _setCheckoutIndex(
        _sortBy(tickets, (ticketState) => ticketState.ticket.title)
    );
    const eventId = _getEventId(tickets);
    return {
        ...state,
        [eventId]: {
            ...state[eventId],
            tickets: [...ticketsWithCheckoutIndex],
        },
    };
};

const addTicket = (state: TicketsState, { ticket: ticketForCart }: { ticket: TicketCart }) => {
    const lengthTicketsForEvent = _getTicketsForEvent(state, ticketForCart.event.id)?.length || 0;
    ticketForCart.checkoutIndex = lengthTicketsForEvent;

    const newTickets = _setCheckoutIndex(
        _sortBy(
            [...(state[ticketForCart.event.id]?.tickets || []), ticketForCart],
            (ticketState) => ticketState.ticket.title
        )
    );

    return {
        ...state,
        [ticketForCart.event.id]: {
            ...state[ticketForCart.event.id],
            tickets: newTickets,
        },
    };
};

const addTicketsOfType = (state: TicketsState, { tickets: ticketsForCart }: { tickets: TicketCart[] }) => {
    const newTickets = _setCheckoutIndex(
        _sortBy(
            [...(state[ticketsForCart[0].event.id]?.tickets || []), ...ticketsForCart],
            (ticketState) => ticketState.ticket.title
        )
    );

    return {
        ...state,
        [ticketsForCart[0].event.id]: {
            ...state[ticketsForCart[0].event.id],
            tickets: newTickets,
        },
    };
};

const getTicketIndexForRemove = (state: TicketsState, ticketForRemove: TicketCart) => {
    return _findIndex(
        state[ticketForRemove.event.id].tickets,
        (ticketCart) =>
            ticketCart.ticket.id === ticketForRemove.ticket.id &&
            ticketCart.checkoutIndex === ticketForRemove.checkoutIndex
    );
};
const removeTicket = (state: TicketsState, action: { ticket: TicketCart }) => {
    if (state[action.ticket.event.id].tickets.length === 1) {
        return removeAllTicketsForEvent(state, action.ticket.event.id);
    }

    const ticketIndex = getTicketIndexForRemove(state, action.ticket);
    const ticketForEvent = _getTicketsForEvent(state, action.ticket.event.id);
    ticketForEvent.splice(ticketIndex, 1);

    _forEach(ticketForEvent, (ticketCart, index) => {
        ticketCart.checkoutIndex = index;
    });

    return _createNewTickets(state, action.ticket.event.id);
};

const removeAllTicketsForEvent = (state: TicketsState, eventId: string) => {
    delete state[eventId];
    return { ...state };
};

//--- private -----

const _getTicketsForEvent = (state: TicketsState, eventId: string) => {
    return state[eventId]?.tickets;
};

const _getEventId = (tickets: TicketCart[]) => {
    return tickets[0].event.id;
};

const _setCheckoutIndex = (tickets: TicketCart[]) => {
    _forEach(tickets, (ticket, index) => (ticket.checkoutIndex = index));
    return tickets;
};

const _createNewTickets = (state: TicketsState, eventId: string) => {
    return {
        ...state,
        [eventId]: {
            ...state[eventId],
            tickets: [...(state[eventId]?.tickets || [])],
        },
    };
};
