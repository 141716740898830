import * as api from "$helper/api";
import { TicketModel, TicketDTO, mapToTicketDTO } from "@djordjeandjelkovic/medgress_common_react_modules";

const getTickets = async (acronym: string): Promise<TicketDTO[]> => {
    const ticketsList = await api.get<TicketModel[]>(`/tickets/events/acronym/${acronym}?isList=true`);
    if (ticketsList === null) {
        return [];
    }
    return ticketsList.map(ticket => {
        return mapToTicketDTO(ticket);
    });
}

export const ticketService = {
    getTickets
}
