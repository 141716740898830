import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            position: "absolute",
            top: 10,
            right: 5,
        },
    })
);
