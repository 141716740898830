import { useLiterals } from "$hooks/Translate/useLiterals";
import { useState } from "react";
import { userService } from "$services/core";
import { EventChange, IUser } from "@djordjeandjelkovic/medgress_common_react_modules";
import { userProfile } from "$config/config";
import { authenticationService } from "$services/core";
import { useMessage } from "$helper/useMessage";

interface IState {
    oldPassword: string;
    password: string;
    confirmPassword: string;
}

interface IProps {
    user: IUser | undefined;
}

interface IActions {
    handleChangePassword: () => void;
    handleChange: (event: EventChange<string>) => void;
}

const useChangePasswordState = (props: IProps): [IState, IActions] => {
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const { user } = props;

    const { showError, showSuccess } = useMessage();
    const getLiteral = useLiterals();

    const isOutOfRange = (password: string) => {
        return (
            password.length < userProfile.passwordLengthMin || password.length > userProfile.passwordLengthMax
        );
    };

    const handleChangePassword = async () => {
        if (!user) {
            return;
        }

        if (authenticationService.isLoggedInByGoogle()) {
            showError(getLiteral("login.error.password_can_be_updated_only_with_login_with_email"));
            return;
        }

        if (isOutOfRange(oldPassword) || isOutOfRange(password)) {
            showError(
                getLiteral(
                    "login.error.invalid_password",
                    userProfile.passwordLengthMin,
                    userProfile.passwordLengthMax
                )
            );
            return;
        }

        if (password !== confirmPassword) {
            showError(getLiteral("user_profile.user_details.form.change_password.password_match_error"));
            return;
        }

        try {
            await userService.changePassword({
                ...user,
                password: password || "",
                oldPassword: oldPassword || "",
            });
            showSuccess(getLiteral("user_profile.user_details.form.change_password.password_changed"));
        } catch (error) {
            console.error(error);
            showError(getLiteral(error));
        }
    };

    const handleChange = (event: EventChange<string>) => {
        const {
            target: { name, value },
        } = event;

        switch (name) {
            case "old-password":
                setOldPassword(value as string);
                break;
            case "new-password":
                setPassword(value as string);
                break;
            case "confirm-password":
                setConfirmPassword(value as string);
                break;
        }
    };

    return [
        { oldPassword, password, confirmPassword },
        { handleChange, handleChangePassword },
    ];
};

export { useChangePasswordState };
