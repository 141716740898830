import React, { useState } from "react";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { useStyles } from "../styles";
import { Typography } from "@material-ui/core";
import { SignUp } from "$components/SignUp";
import { ForgotPassword } from "./ForgotPassword";

const SignUpOrForgotPassword = () => {
    const getLiteral = useLiterals();
    const classes = useStyles();
    const [openSignUp, setOpenSignUp] = useState(false);
    const [openForgotPassword, setOpenForgotPassword] = useState(false);

    const toggleOpenSignUp = () => {
        setOpenSignUp((openSignUp) => !openSignUp);
    };

    const handleCloseSignUp = () => {
        setOpenSignUp(false);
    };

    const toggleOpenForgotPassword = () => {
        setOpenForgotPassword((openSignUp) => !openSignUp);
    };

    const handleCloseForgetPassword = () => {
        setOpenForgotPassword(false);
    };

    return (
        <>
            <div className={classes.signUp}>
                <Typography variant="caption" onClick={toggleOpenSignUp}>
                    {getLiteral("sign_in.sign_up")}
                </Typography>
                <br />
                <Typography variant="caption" onClick={toggleOpenForgotPassword}>
                    {getLiteral("sign-up.forgot_password.lnk_forgot_password")}
                </Typography>
            </div>

            <SignUp open={openSignUp} onClose={handleCloseSignUp} />
            <ForgotPassword open={openForgotPassword} onClose={handleCloseForgetPassword} />
        </>
    );
};

export { SignUpOrForgotPassword };
