import { TicketCart } from "$models/types";

export const ON_CHANGE = "ON_CHANGE" as "ON_CHANGE";
export const VALIDATION = "VALIDATION_ATTENDEES" as "VALIDATION_ATTENDEES";
export const SET_ATTENDEE_ID = "SET_ATTENDEE_ID" as "SET_ATTENDEE_ID";
export const REMOVE_ATTENDEE = "REMOVE_ATTENDEE" as "REMOVE_ATTENDEE";
export const REMOVE_ATTENDEES = "REMOVE_ATTENDEES" as "REMOVE_ATTENDEES";

export const ATTENDEE = "ATTENDEE" as "ATTENDEE";

export type AttendeeAction =
    | {
          type: typeof ON_CHANGE;
          altType: typeof ATTENDEE;
          index: number;
          eventId: string;
          name: string;
          value: string | string[];
      }
    | {
          type: typeof VALIDATION;
          altType: typeof ATTENDEE;
          eventId: string;
          index: number;
          attendee: TicketCart;
      }
    | { type: typeof SET_ATTENDEE_ID; altType: typeof ATTENDEE; index: number; eventId: string; id: string }
    | { type: typeof REMOVE_ATTENDEE; altType: typeof ATTENDEE; index: number; eventId: string }
    | { type: typeof REMOVE_ATTENDEES; altType: typeof ATTENDEE; eventId: string };
