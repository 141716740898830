import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            width: "100%",
            maxWidth: 780,
            margin: `${theme.typography.pxToRem(40)} auto`,
            padding: "0 40px",
            boxSizing: "border-box",
        },
        wrapperWithTotal: {
            float: "right",
            margin: `${theme.typography.pxToRem(40)} 0`,
        },
        actions: ({ isAttendeeStep }: { isAttendeeStep?: boolean }) => ({
            justifyContent: isAttendeeStep ? "space-between" : "flex-end",
            borderTop: "1px solid #bdbdbd",
            paddingTop: 20,
            paddingBottom: 20,
            marginTop: 20,
        }),
        nextBtn: {
            marginLeft: 10,
            minWidth: 120,
        },
        activeStep: {
            color: theme.palette.primary.main,
            fontSize: "2rem",
            position: "relative",
            bottom: 5,
            boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
            borderRadius: "1rem",
        },
        completedStep: {
            color: theme.palette.primary.main,
        },
        eventDetailsWrapper: {
            padding: 20,
        },
        eventDetailsIcon: {
            width: 100,
            height: 100,
        },
        eventDetailsTitle: {
            position: "relative",
            top: "50%",
            marginTop: -16,
        },
        rightSide: {
            height: 400,
        },
        paymentTitle: {
            padding: "20px 0",
        },
        paymentProgress: {
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
        paymentButton: {
            marginTop: 30,
            marginBottom: 10,
        },
        additionalServices: {
            marginTop: 40,
        },
        paymentAccordionTitle: {
            padding: 9,
        },
        paymentAccordionImg: {
            padding: 9,
            boxSizing: "content-box",
            position: "absolute",
            right: 10,
        },
    })
);

export { useStyles };
