import React from "react";

import { DropDownListField, EventChange, TicketDTO } from "@djordjeandjelkovic/medgress_common_react_modules";
import { useStyles } from "./styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTicketsUtils, ticketName } from "$helper/useTicketsUtils";

export const TicketGrid = ({
    tickets,
    ticket,
    hidden,
    onTicketChange,
}: {
    ticket: TicketDTO;
    tickets: TicketDTO[];
    hidden: boolean;
    onTicketChange: (event: EventChange<string>) => void;
}) => {
    const classes = useStyles();
    const { getPriceInfo } = useTicketsUtils();
    return (
        <Grid item xs={12} className={classes.ticketTitleContainer}>
            {isOne(tickets) ? (
                <Typography variant={hidden ? "h5" : "subtitle1"} className={classes.ticketTitle}>
                    {ticket.title.toUpperCase()}
                </Typography>
            ) : (
                <DropDownListField
                    label="Select ticket"
                    name="ticket"
                    value={ticket.id}
                    options={tickets.map((t) => ({
                        name: ticketName(t),
                        value: t.id,
                    }))}
                    onChange={onTicketChange}
                />
            )}
            <Typography variant={hidden ? "h5" : "subtitle1"} className={classes.ticketPrice}>
                {getPriceInfo(ticket)}
            </Typography>
        </Grid>
    );
};

function isOne(tickets: TicketDTO[]) {
    return tickets.length === 1;
}
