import React from "react";
import Button from "@material-ui/core/Button";

import { SpinnerButton } from "@djordjeandjelkovic/medgress_common_react_modules";
import { engineUrl } from "$helper/api";

import useTicketFooterActions from "./useTicketFooterActions";
import { useStyles } from "../styles";

type TicketFooterActionsProps = {
    attendeeId: string;
    cartId: string;
    onView: React.MouseEventHandler<HTMLButtonElement>;
    eventId: string;
    showTicketActions: boolean;
};

const TicketFooterActions = (props: TicketFooterActionsProps) => {
    const { attendeeId, cartId, eventId, showTicketActions, onView } = props;
    const [{
        isLoadingResend
    }, {
        handleResendTicket
    }] = useTicketFooterActions({ cartId: cartId, attendeeId: attendeeId });
    const classes = useStyles();

    if(!showTicketActions) {
        return null;
    }

    return (
        <div className={classes.ticketActions}>
            <SpinnerButton
                isLoading={isLoadingResend!}
                onClick={handleResendTicket}
                title="Resend"
                ButtonProps={{
                    color: "primary",
                    variant: "outlined",
                    size: "medium",
                }}
            />
            <Button color="primary" variant="outlined" onClick={onView} className={classes.ticketAction}>
                View ticket
            </Button>
            <Button
                color="primary"
                variant="outlined"
                target="_blank"
                href={`${engineUrl()}/ticket-carts/download/events/${eventId}/attendees/${attendeeId}`}>
                Download ticket
            </Button>
        </div>
    );
};

export default TicketFooterActions;
