import * as api from "$helper/api";
import { IUserDTO, IUser, ISetPasswordDTO, IChangePasswordDTO, toUserDTO } from "@djordjeandjelkovic/medgress_common_react_modules";

const prefix = "/users";

const getUser = () => {
    return api.get<IUserDTO>(`${prefix}/from-token`);
}

const update = (user: IUser) => {
    return api.put<IUserDTO, any>(`${prefix}/${user.id}`, toUserDTO(user));
}

const changePassword = (user: IChangePasswordDTO) => {
    return api.put<IChangePasswordDTO, any>(`${prefix}/${user.id}/password`, user);
}

const setPassword = (userId: string, setPassword: ISetPasswordDTO) => {
    return api.put<ISetPasswordDTO, any>(`${prefix}/${userId}/set-password`, setPassword);
}

const hasPassword = () => {
    return api.get<boolean>(`${prefix}/has-password`);
};

const resetPassword = (user: IUser) => {
    return api.post<IUserDTO, any>(`${prefix}/password/reset`, toUserDTO(user));
};

export const userService = {
    getUser,
    update,
    changePassword,
    setPassword,
    hasPassword,
    resetPassword,
};
