import React from "react";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";

import { TicketsLoader, TopBorderContainer } from "$components/core";
import { useStyles } from "./styles";
import { TicketsProps } from "./TicketsProps";
import { useTicketsState } from "./useTicketsState";
import { TicketDTO } from "@djordjeandjelkovic/medgress_common_react_modules";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { Ticket } from "./Ticket";
import { events } from "$config/config";
import _map from "lodash/map";

const Tickets = (props: TicketsProps) => {
    const { preview, embeded, event } = props;
    const [
        { tickets, ticketsCount, isLoading },
        { handleChangeTicketCount, addTicketsToCart, isHiddenTicketsCount },
    ] = useTicketsState(props);
    const classes = useStyles();
    const getLiteral = useLiterals();

    if (!tickets) {
        return <TicketsLoader />;
    }

    return (
        <TopBorderContainer title={getLiteral("event_details.ticket.title")} className={classes.paper}>
            <Grid container>
                <Grid item className={classes.tickets} xs={12}>
                    {_map(tickets, (group: TicketDTO[]) => {
                        const ticket = group[0];
                        return (
                            <React.Fragment key={ticket.groupId}>
                                <Ticket
                                    embeded={embeded}
                                    showAvailableTickets={ticket.displayLimit}
                                    onTicketCountChange={handleChangeTicketCount}
                                    onAddToCart={addTicketsToCart}
                                    disabled={preview || false}
                                    hidden={isHiddenTicketsCount(ticket)}
                                    isLoading={isLoading}
                                    ticketsCount={ticketsCount}
                                    acronym={event.acronym}
                                    tickets={group}
                                />
                                <Divider className={classes.divider} />
                            </React.Fragment>
                        );
                    })}
                </Grid>
                <Grid item className={classes.tickets} xs={12}>
                    <BuyNowButton
                        disabled={preview || !ticketsCount.hasSelectedTicket!() || isLoading}
                        onAddTickets={addTicketsToCart}
                        acronym={event.acronym}
                        embeded={Number(embeded)}
                    />
                </Grid>
            </Grid>
        </TopBorderContainer>
    );
};

enum EMBEDED {
    FALSE = 0,
    TRUE = 1,
}
type BuyNowButtonProps = {
    disabled: boolean;
    onAddTickets: () => void;
    acronym: string;
    embeded?: EMBEDED;
};
const BuyNowButton = ({ disabled, onAddTickets, acronym, embeded }: BuyNowButtonProps) => {
    const classes = useStyles();
    const getLiteral = useLiterals();

    const buttonProps: { [key: number]: any } = {
        [EMBEDED.FALSE]: {
            color: "primary",
            variant: "contained",
            fullWidth: true,
            size: "large",
            disabled: disabled,
            onClick: () => onAddTickets(),
        },
        [EMBEDED.TRUE]: {
            color: "primary",
            variant: "contained",
            fullWidth: true,
            size: "large",
            disabled: disabled,
            target: "_blank",
            href: `${events.url}/events/${acronym}`,
        },
    };

    return (
        <Button {...buttonProps[embeded || EMBEDED.FALSE]}>
            <AddShoppingCartIcon className={classes.buyNowIcon} />
            {getLiteral("event_details.ticket.button.buy_now")}
        </Button>
    );
};

export { Tickets };
