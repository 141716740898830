import React, { useState } from "react";
import { Grid, Container } from "@material-ui/core";
import { PageHeader, TopBorderContainer } from "$components/core";
import { useStyles } from "./styles";
import { useUserProfileState } from "./useUserProfileState";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { UserActions } from "./UserActions/UserActions";
import { UserDetails } from "./UserDetails/UserDetails";
import { ChangePassword } from "./ChangePassword/ChangePassword";
import { SetPassword } from "./SetPassword/SetPassword";
import { Downloads } from "./Downloads/Downloads";

const UserProfile = () => {
    const classes = useStyles();
    const getLiteral = useLiterals();

    const [{ user, userHasPassword }, { handleUpdateClick }] = useUserProfileState();

    const [currentAction, setCurrentAction] = useState<string>("userDetails");

    if(!user) {
        return <h4>Loading...</h4>
    }

    const getActionContent = () => {
        switch(currentAction) {
            case "userDetails":
                return <UserDetails user={user} handleUpdateClick={handleUpdateClick} />;
            case "changePassword":
                return <ChangePassword user={user} />;
            case "setPassword":
                return <SetPassword user={user} />;
            case "downloads":
                return <Downloads />;
            default:
                return <UserDetails user={user} handleUpdateClick={handleUpdateClick} />;
        }
    }

    const handleChangeAction = (action: string) => {
        setCurrentAction(action);
    }    
    
    return (
        <>
            <PageHeader title={getLiteral("user_profile.title")} />
            <Container>
                <Grid container>
                    <Grid item lg={4} xs={12}>
                        <UserActions user={user} userHasPassword={userHasPassword} currentAction={currentAction} changeAction={handleChangeAction} />
                    </Grid>
                    <Grid item lg={8} xs={12}>
                        <TopBorderContainer className={classes.paper}>
                            {getActionContent()}
                        </TopBorderContainer>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export { UserProfile };
