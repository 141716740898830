import * as api from "$helper/api";

import { User } from "@djordjeandjelkovic/medgress_common_react_modules";

const addUser = async(user: User): Promise<string> => {
    return api.post<User, string>("users/insert/registration", user);
}

const activateUser = async(serializedUser: string) => {
    return api.post<{}, string>(`users/activate?u=${serializedUser}`, {});
}

export const signupService = {
    addUser,
    activateUser,
};
