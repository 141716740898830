import { useTheme } from "@material-ui/core";
import * as React from "react"

import ContentLoader from "react-content-loader"

export const TicketsLoader: React.FunctionComponent = () => {
    const theme = useTheme();
    
    return (
        <ContentLoader viewBox="0 0 200 200" backgroundColor={theme.palette.grey[400]} foregroundColor={theme.palette.grey[300]}>
            <rect x="5" y="10" rx="0" ry="0" width="200" height="5" />
            <rect x="5" y="20" rx="0" ry="0" width="70" height="15" />
            <rect x="5" y="40" rx="0" ry="0" width="30" height="10" />
            <rect x="5" y="55" rx="0" ry="0" width="100" height="5" />
			<rect x="5" y="62" rx="0" ry="0" width="100" height="5" />
            <rect x="135" y="30" rx="0" ry="0" width="70" height="30" />

            <rect x="5" y="80" rx="0" ry="0" width="200" height="5" />
            <rect x="5" y="90" rx="0" ry="0" width="70" height="15" />
            <rect x="5" y="110" rx="0" ry="0" width="30" height="10" />
            <rect x="5" y="125" rx="0" ry="0" width="100" height="5" />
            <rect x="5" y="132" rx="0" ry="0" width="100" height="5" />
            <rect x="135" y="100" rx="0" ry="0" width="70" height="30" />

            <rect x="5" y="145" rx="0" ry="0" width="200" height="30" />
        </ContentLoader>
    )
}