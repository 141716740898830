import { combineReducers } from "redux";
import { ticketsCartMainReducer } from "./ticketsCartReducer";
import { checkoutMainReducer } from "./checkoutReducer";
import { literalReducer } from "./literalReducer";
import { paymentReducer } from "./paymentReducers";
import { themeReducer } from "./themeReducer";
import { PersistPartial } from "redux-persist/lib/persistReducer";
import { cartTimeoutReducer } from "./cartTimeoutReducer";

const appReducer = combineReducers({
    checkout: checkoutMainReducer,
    literal: literalReducer,
    payment: paymentReducer,
    ticketsCart: ticketsCartMainReducer,
    theme: themeReducer,
    cartTimeout: cartTimeoutReducer,
});

export const rootReducer = (state: any, action: any) => {
    if (action.type === "RESET_APP") {
        state = undefined;
    }

    return appReducer(state, action);
};

export type ReduxStore = ReturnType<typeof rootReducer> & PersistPartial;
