import * as React from "react";

import { Grid, Typography } from "@material-ui/core";
import { styles } from "./styles";

const Copyright = () => {
    const classes = styles();
    
    return (
        <Grid className={classes.root}>
            <Typography variant="body1" className={classes.header}>©2021 All right reserved | Privacy policy</Typography>
        </Grid>
    );    
};

export { Copyright };
