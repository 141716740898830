import { useEffect } from "react";
import _forEach from "lodash/forEach";
import _keys from "lodash/keys";

export const useVersion = () => {
    useEffect(() => {
        const version = window.localStorage.getItem("version");
        if (version !== process.env.REACT_APP_VERSION) {
            deleteLocalStorage();
        }
    }, []);
};

const deleteLocalStorage = () => {
    _forEach(_keys(window.localStorage), (key) => {
        window.localStorage.removeItem(key);
    });
    window.localStorage.setItem("version", process.env.REACT_APP_VERSION);
};
