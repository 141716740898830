import { useEffect, useRef } from "react";

interface IPrevious {
    [key: string]: {} | undefined;
}

const usePrevious = <T extends {}>(key: string | undefined, value: T | undefined): T | undefined => {
    const ref = useRef<IPrevious | undefined>(getPrev(key));

    useEffect(() => {
        if (!key) {
            ref.current = undefined;
            return;
        }

        if (typeof ref.current === "undefined") {
            ref.current = { [key]: value };
        } else {
            ref.current[key] = value;
        }
        localStorage.setItem(key, JSON.stringify(ref.current));
    });

    if (!key) {
        return undefined;
    }

    return typeof ref.current === "undefined" ? undefined : (ref.current[key] as T);
};

function getPrev(key: string | undefined): IPrevious | undefined {
    if (!key) {
        return undefined;
    }

    const previous = localStorage.getItem(key);
    let prev: IPrevious | undefined;
    if (previous == null) {
        prev = undefined;
    } else {
        prev = JSON.parse(previous);
    }

    return prev;
}

export { usePrevious };
