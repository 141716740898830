import { Theme, makeStyles, createStyles } from "@material-ui/core";

export const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: 400,
            paddingTop: 30,
            background: theme.palette.secondary.light,
            color: theme.palette.text.primary,
        },
        list: {
            listStyleType: "none",
            paddingLeft: 10,
        },
        listCenter: {
            textAlign: "center",
        },
        listItem: {
            padding: "5px 0 6px 0",
        },
        footerItem: {
            marginTop: 50,
        },
        footerNetwork: {
            textAlign: "center",
        },
        link: {
            color: theme.palette.text.primary,
            padding: 20,
        },
        header: {
            paddingBottom: 10,
            textAlign: "center",
        },
        icon: {
            verticalAlign: "middle",
            paddingRight: 5,
            color: theme.palette.text.primary,
        },
    })
);
