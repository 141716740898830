import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tickets: {
            padding: theme.spacing(3),
        },
        title: {
            padding: theme.spacing(3),
            fontWeight: "bold",
        },
        paper: {
            height: "100%",
        },
        ticketIcon: {
            position: "relative",
            top: 7,
            right: 12,
        },
        addToCart: {
            position: "relative",
            top: 12,
            left: 5,
        },
        buyNowIcon: {
            marginRight: 5,
        },
        divider: {
            marginTop: 20,
            marginBottom: 20,
        },
        ticketTitleContainer: {
            display: "flex",
            justifyContent: "space-between",
        },
        ticketTitle: {
            display: "inline-block",
        },
        ticketPrice: {
            display: "inline-block",
            fontWeight: "bold",
        },
    })
);
