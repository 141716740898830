import {
    CartTimeoutAction,
    DELETE_TIMEOUT,
    INIT,
    SET_CART_ID_INIT,
    SET_CART_TIMEOUT,
} from "$redux/actions/CartTimeout/cartTimeoutActionsTypes";
import _forEach from "lodash/forEach";
import _keys from "lodash/keys";
import { cartTimeout } from "$config/config";
import { utils } from "$helper/utils";

type Action = CartTimeoutAction;
export type CartTimeoutState = {
    [eventId: string]: {
        startTime: Date;
        timeout: number;
        cartId?: string;
    };
};
const SECONDS_IN_MINUTES = 60;
const MILISECONDS_IN_SECOND = 1000;

export const cartTimeoutReducer = (state: CartTimeoutState = {}, action: Action) => {
    switch (action.type) {
        case SET_CART_TIMEOUT:
            return decrementCartTimeout(state);
        case DELETE_TIMEOUT:
            return deleteTimeout(state, action);
        case INIT:
            return initTimeout(state, action);
        case SET_CART_ID_INIT:
            return setCartIdForTimeout(state, action);
        default:
            return state;
    }
};

const decrementCartTimeout = (state: CartTimeoutState) => {
    const updatedState: CartTimeoutState = {};
    _forEach(_keys(state), (key) => {
        const startTime = state[key].startTime;
        const timeout = calculateTimeout(startTime);

        if (state[key].timeout - timeout <= getSecondsFromMinutes(cartTimeout.time)) {
            updatedState[key] = {
                ...state[key],
                startTime: typeof startTime === "string" ? utils.dateFromString(startTime) : startTime,
                timeout: getSecondsFromMinutes(cartTimeout.time) - timeout,
            };
        } else {
            updatedState[key] = state[key];
        }
    });

    return updatedState;
};

const deleteTimeout = (state: CartTimeoutState, action: { eventId: string }) => {
    const updatedState: CartTimeoutState = {};
    _forEach(_keys(state), (key) => {
        if (key !== action.eventId) {
            updatedState[key] = state[key];
        }
    });

    return updatedState;
};

const initTimeout = (state: CartTimeoutState, action: { eventId: string }) => {
    return {
        ...state,
        [action.eventId]: {
            ...state[action.eventId],
            timeout: getSecondsFromMinutes(cartTimeout.time),
            startTime: utils.dateNow(),
        },
    };
};

const setCartIdForTimeout = (state: CartTimeoutState, action: { eventId: string; cartId: string }) => {
    return {
        ...state,
        [action.eventId]: { ...state[action.eventId], cartId: action.cartId },
    };
};

const getSecondsFromMinutes = (minutes: number) => {
    return minutes * SECONDS_IN_MINUTES;
};

const calculateTimeout = (startTime: Date) => {
    if (typeof startTime === "string") startTime = new Date(startTime);
    const currentDate = utils.dateNow();
    return Math.round((currentDate.getTime() - startTime.getTime()) / MILISECONDS_IN_SECOND);
};
