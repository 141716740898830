import { Theme, makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            clear: "both",
            minHeight: 450,
            position: "relative",
        },
    })
);
