import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            padding: 20,
            textAlign: "center",
        },
        title: {
            paddingTop: 20,
        },
        button: {
            marginTop: 20,
        },
    })
);
