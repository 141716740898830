import * as CheckoutActionCreators from "$redux/actions/Checkout/checkoutActions";
import * as BuyerActionCreators from "$redux/actions/Buyer/buyerActions";
import * as AttendeeActionCreators from "$redux/actions/AttendeeFields/attendeeFieldsActions";
import * as TicketsCartActionCreators from "$redux/actions/TicketsCart/ticketsCartActions";
import * as PaymentActionCreators from "$redux/actions/Payment/paymentActions";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

export const useCheckoutActionCreators = () => {
    const dispatch = useDispatch();
    const { setActiveStep } = bindActionCreators(CheckoutActionCreators, dispatch);
    const { setValidationBuyer } = bindActionCreators(BuyerActionCreators, dispatch);
    const { setValidationAttendee, setAttendeeId } = bindActionCreators(AttendeeActionCreators, dispatch);
    const { removeTicket, removeAllTickets } = bindActionCreators(TicketsCartActionCreators, dispatch);
    const { setTerms } = bindActionCreators(PaymentActionCreators, dispatch);

    return {
        setActiveStep,
        setValidationBuyer,
        setValidationAttendee,
        setAttendeeId,
        removeTicket,
        removeAllTickets,
        setTerms,
    };
};
