import React from "react";
import { ITicketCartPopper } from "@djordjeandjelkovic/medgress_common_react_modules";
import { RemoveButton } from "./RemoveButton";
import { useStyles } from "./styles";
import _map from "lodash/map";
import { Paper, Typography } from "@material-ui/core";
import { DECIMAL_PLACES } from "$constants/Numbers";
import ReceiptIcon from "@material-ui/icons/Receipt";

type TicketsInCartProps = {
    onRemove: (id: string, eventId: string) => void;
} & ITicketCartPopper;

export const TicketsInCart = (props: TicketsInCartProps) => {
    const { tickets, event, onRemove } = props;
    const classes = useStyles();
    return (
        <>
            {_map(tickets, (ticket, index) => (
                <Paper key={ticket.id + "_" + index} classes={{ root: classes.paperRoot }}>
                    <ReceiptIcon className={classes.ticketIcon} />
                    <Typography variant="button">{ticket.type}</Typography>
                    <Typography variant="button">
                        - {ticket.currency} {ticket.price?.amount.toFixed(DECIMAL_PLACES)}
                    </Typography>
                    <RemoveButton id={ticket.id} eventId={event.id} onClick={onRemove} />
                </Paper>
            ))}
        </>
    );
};
