import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({});

export const defaultStyle = createTheme({
    shape: {
        borderRadius: 3,
    },
    overrides: {
        MuiTypography: {
            h3: {
                fontWeight: 400,
                [breakpoints.down("xs")]: {
                    fontSize: "2rem",
                },
            },
            h4: {
                fontWeight: "bold",
                [breakpoints.down("xs")]: {
                    fontSize: "1.5rem",
                },
            },
            h5: {
                fontWeight: "bold",
            },
        },
        MuiButton: {
            sizeLarge: {
                padding: "15px 22px",
            },
        },
    },
});