export const centra = {
    url: process.env.REACT_APP_CENTRA_URL,
};

export const engine = {
    apiUrl: process.env.REACT_APP_API_URL,
};

export const payment = {
    url: process.env.REACT_APP_PAYMENT_URL,
    accessCode: process.env.REACT_APP_PAYMENT_ACCESS_CODE,
    mIdentifier: process.env.REACT_APP_PAYMENT_MERCHANT_ID,
};

export const announcement = {
    applicationId: process.env.REACT_APP_APPLICATION_ID,
}

export const userProfile = {
    passwordLengthMin: Number(process.env.REACT_APP_USER_PASSWORD_LENGTH_MIN),
    passwordLengthMax: Number(process.env.REACT_APP_USER_PASSWORD_LENGTH_MAX),
}

export const recaptcha = {
    enabled: process.env.REACT_APP_RECAPTCHA_ENABLED,
    siteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
}

export const googleLogin = {
    clientId: process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID,
}

export const cartTimeout = {
    time: Number(process.env.REACT_APP_CARTTIMEOUT_MINUTES) || 30,
};

export const events = {
    url: process.env.REACT_APP_EVENTS_URL,
};
