import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperHistory: {
            position: "relative",
            maxWidth: 1200,
            margin: `${theme.spacing(3)}px auto`,
            padding: theme.spacing(4),
            textAlign: "center",
            borderTop: theme.palette.secondary.light,
            borderStyle: "solid",
        },
        paperHistoryDetails: {
            position: "relative",
            maxWidth: 800,
            margin: `${theme.spacing(3)}px auto`,
            padding: theme.spacing(4),
        },
        card: {
            marginBottom: theme.spacing(1),
            borderTop: theme.palette.secondary.light,
            borderStyle: "solid",
        },
        cardActions: {
            padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        },
        updateButton: {
            marginLeft: "auto",
        },
        cardContent: {
            padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        },
        tableRow: {
            cursor: "pointer",
        },
        loading: {
            position: "absolute",
            top: "50%",
            left: "50%",
        },
        loading40: {
            marginLeft: -20,
            marginTop: -20,
        },
        pdfPage: {
            display: "flex",
            justifyContent: "center",
        },
        ticketActions: {
            textAlign: "right",
            paddingTop: 20,
            marginTop: 20,
            borderTop: "1px solid",
        },
        ticketAction: {
            marginRight: 10,
            marginLeft: 10,
        }
    })
);
