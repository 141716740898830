import Grid, { GridProps } from "@material-ui/core/Grid";
import React from "react";
import { SideTotal } from "./SideTotal";

type ShowSideTotalGridProps = {
    visible?: boolean;
    eventId?: string;
} & GridProps;

export const ShowSideTotalGrid = (props: ShowSideTotalGridProps) => {
    const { visible, eventId, ...rest } = props;

    if (!visible) return null;

    return (
        <Grid {...rest}>
            <SideTotal eventId={eventId} />
        </Grid>
    );
};
