import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
    Grid,
    InputAdornment,
    Dialog,
    DialogContent,
    Typography,
    useMediaQuery,
    Theme,
    Divider,
} from "@material-ui/core";
import Person from "@material-ui/icons/Person";
import Call from "@material-ui/icons/Call";
import Email from "@material-ui/icons/Email";
import Work from "@material-ui/icons/Work";
import {
    User,
    PhoneField,
    ApiErrors,
    DialogCloseButton,
    SpinnerButton,
    PasswordField,
    EventChange,
    TextField,
    EmailField,
} from "@djordjeandjelkovic/medgress_common_react_modules";
import { useLiterals } from "$hooks/Translate/useLiterals";
import images from "$assets/images";
import { signupService } from "$services/core";
import { useStyles } from "../styles";
import { useFormState } from "./formState";
import { userProfile } from "$config/config";
import { GoogleLogin } from "react-google-login";
import { authenticationService } from "$services/core";
import * as config from "$config/config";
import { useMessage } from "$helper/useMessage";
import { isValidEmail } from "@djordjeandjelkovic/medgress_common_react_modules";

interface SignUpFormProps {
    onSignUp?: () => void;
    handleClose: () => void;
    open: boolean;
}

export interface InputField<T> {
    value?: T;
    error?: string;
    additionalOptions?: any;
}

export interface SignUpFormState {
    firstName: InputField<string>;
    lastName: InputField<string>;
    email: InputField<string>;
    phoneNumber?: InputField<string>;
    password: InputField<string>;
    confirmPassword: InputField<string>;
}

const SignUpForm = (props: SignUpFormProps) => {
    const classes = useStyles();
    const recaptcha: any = useRef();
    const [isLoading, setLoading] = useState(false);
    const [, setRecaptchaValue] = useState<InputField<string>>({ value: "", error: "" });
    const isSmallerThenSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
    const [
        state,
        {
            setFirstName,
            setLastName,
            setEmail,
            setPhone,
            setPassword,
            setConfirmPassword,
            resetStateError,
            setFormValidationResult,
            reset,
        },
    ] = useFormState();

    const { showError } = useMessage();

    const customProps = React.useMemo(
        () => ({
            firstName: {
                TextFieldProps: {
                    variant: "outlined" as "outlined",
                    InputProps: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <Person />
                            </InputAdornment>
                        ),
                    },
                },
            },
            lastName: {
                TextFieldProps: {
                    variant: "outlined" as "outlined",
                    InputProps: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <Person />
                            </InputAdornment>
                        ),
                    },
                },
            },
            company: {
                TextFieldProps: {
                    fullWidth: true,
                    variant: "outlined" as "outlined",
                    InputProps: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <Work />
                            </InputAdornment>
                        ),
                    },
                },
            },
            email: {
                TextFieldProps: {
                    variant: "outlined" as "outlined",
                    InputProps: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <Email />
                            </InputAdornment>
                        ),
                    },
                },
            },
            password: {
                variant: "outlined" as "outlined",
            },
            phone: {
                TextFieldProps: {
                    variant: "outlined" as "outlined",
                    fullWidth: true,
                    InputProps: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <Call />
                            </InputAdornment>
                        ),
                    },
                },
                DialCodeTextFieldProps: { variant: "outlined" as "outlined" },
            },
            submitButton: {
                ButtonProps: {
                    color: "primary" as "primary",
                    variant: "contained" as "contained",
                    fullWidth: true,
                },
            },
        }),
        []
    );

    const handleChange = (event: EventChange<string>) => {
        const value = event.target.value;
        const name = event.target.name;

        switch (name) {
            case "firstName":
                setFirstName(value);
                break;
            case "lastName":
                setLastName(value);
                break;
            case "email":
                setEmail(value);
                break;
            case "phoneNumber":
                setPhone(value);
                break;
            case "password":
                setPassword(value);
                break;
            case "confirmPassword":
                setConfirmPassword(value);
                break;
        }
    };

    const handlePhoneNumberChange = (event: { target: { value: string; name: string } }) => {
        const value: string = event.target.value;
        setPhone(value);
    };

    const toggleLoading = () => {
        setLoading((state) => !state);
    };

    const mapStateToUser = () => {
        const { firstName, lastName, email, phoneNumber, password } = state;

        return new User(
            firstName.value || "",
            lastName.value || "",
            email.value || "",
            password.value || "",
            phoneNumber!.value || ""
        );
    };

    const tryReCaptcha = () => {
        // if (process.env.NODE_ENV === "development") {
        //     return true;
        // }

        // if (!recaptcha || !recaptcha.current) {
        //     return true;
        // }

        // try {
        //     recaptcha.current.execute();
        // } catch {
        //     return false;
        // }

        return true;
    };

    const validateRecaptcha = (
        recaptcha: InputField<string>,
        update: (recaptcha: InputField<string>) => void
    ): boolean => {
        if (config.recaptcha.enabled === "false") {
            console.log(`Recaptcha is disabled.`);
            return true;
        }

        if (!recaptcha.value) {
            update({ value: recaptcha!.value, error: literals("sign_up.validation.recaptcha_value") });
            return false;
        }

        return true;
    };

    const validateForm = (state: SignUpFormState, update: (state: SignUpFormState) => void): boolean => {
        const error: string[] = ["", "", "", "", "", ""];

        const requiredLiteral = literals("sign_up.validation.required");

        if (!state.firstName.value) error[0] = requiredLiteral;
        if (!state.lastName.value) error[1] = requiredLiteral;
        if (!state.password.value) error[3] = requiredLiteral;
        if (!state.confirmPassword.value) error[4] = requiredLiteral;
        if (!state.email.value) {
            error[2] = requiredLiteral;
        } else if (!isValidEmail(state.email.value)) {
            error[2] = literals("sign_up.validation.invalid_email");
            showError(literals("sign_up.validation.invalid_email"));
        }

        if (
            (state.password.value && state.password.value.length < userProfile.passwordLengthMin) ||
            (state.password.value && state.password.value.length > userProfile.passwordLengthMax)
        ) {
            const passwordLengthErrorMessage = literals(
                "sign_up.validation.password_length",
                userProfile.passwordLengthMin,
                userProfile.passwordLengthMax
            );
            error[3] = passwordLengthErrorMessage;

            showError(passwordLengthErrorMessage);
        }

        if (
            (state.confirmPassword.value &&
                state.confirmPassword.value.length < userProfile.passwordLengthMin) ||
            (state.confirmPassword.value &&
                state.confirmPassword.value.length > userProfile.passwordLengthMax)
        ) {
            const passwordLengthErrorMessage = literals(
                "sign_up.validation.password_length",
                userProfile.passwordLengthMin,
                userProfile.passwordLengthMax
            );
            error[4] = passwordLengthErrorMessage;

            showError(passwordLengthErrorMessage);
        }

        if (state.password.value !== state.confirmPassword.value) {
            const passwordMatchErrorMessage = literals("sign_up.validation.password_match");

            error[3] = passwordMatchErrorMessage;
            error[4] = passwordMatchErrorMessage;

            showError(passwordMatchErrorMessage);
        }

        const isValid = !error.reduce((err, value) => err || value);
        if (!isValid)
            update({
                firstName: { ...state.firstName, error: error[0] },
                lastName: { ...state.lastName, error: error[1] },
                email: { ...state.email, error: error[2] },
                password: { ...state.password, error: error[3] },
                confirmPassword: { ...state.confirmPassword, error: error[4] },
            });

        return isValid;
    };

    const submitUser = () => {
        toggleLoading();

        if (!tryReCaptcha()) {
            toggleLoading();
        }

        /* Uncomment when recpatcha */
        /*if (process.env.NODE_ENV === "development")*/ signUpUser({ value: "", error: "" });
    };

    const handleReCaptchaChange = () => {
        const recaptchaValue = recaptcha!.current!.getValue();
        if (!recaptchaValue) {
            return;
        }

        setRecaptchaValue({ value: recaptchaValue, error: "" });
        signUpUser({ value: recaptchaValue, error: "" });
    };

    const signUpUser = (recaptchaValue: InputField<string>) => {
        if (
            !validateForm(state, (outState) => {
                setFormValidationResult(outState);
            })
        ) {
            toggleLoading();
            return;
        }

        if (
            !validateRecaptcha(recaptchaValue, (out) => {
                showError(literals("sign_up.validation.recaptcha_value"));
                setRecaptchaValue(out);
            })
        ) {
            toggleLoading();
            return;
        }

        signupService
            .addUser(mapStateToUser())
            .then(() => {
                setLoading(false);
                reset();
                props.onSignUp && props.onSignUp();
            })
            .catch((error) => {
                setLoading(false);
                resetStateError();
                showError(literals(`sign_up.response.${error}`));
                console.error(error);
            });
    };

    const handleRecaptchaError = () => {
        console.error("Error while validating recaptcha.");
        showError(literals("sign-up.recaptcha.error"));
        toggleLoading();
    };

    const handleRecaptchaExpire = () => {
        console.error("Error while validating recaptcha.");
        showError(literals("sign-up.recaptcha.expire"));
        toggleLoading();
    };

    const renderReCaptcha = () => {
        if (process.env.NODE_ENV === "development") {
            return;
        }

        return (
            <ReCAPTCHA
                ref={recaptcha}
                size="invisible"
                sitekey={config.recaptcha.siteKey!}
                onChange={handleReCaptchaChange}
                onErrored={handleRecaptchaError}
                onExpired={handleRecaptchaExpire}
            />
        );
    };

    const { firstName, lastName, confirmPassword, password, email, phoneNumber } = state;
    const { handleClose, open } = props;
    const literals = useLiterals();

    const handleGoogleResponse = (response: any) => {
        setLoading(true);
        authenticationService
            .loginWithGoogle(response.tokenId)
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                showError(literals("login.error.invalid_email_or_password"));
                setEmail(email.value, ApiErrors[error]);
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleGoogleFailureResponse = () => {
        showError(literals("login.error.invalid_email_or_password"));
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullScreen={isSmallerThenSm}>
            <DialogTitle onClick={handleClose}>
                <img className={classes.logo} src={images.logo} height="50" alt="logo" />
                <Typography variant="h4" className={classes.title}>
                    {literals("sign_up.title")}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} className={classes.form}>
                    <Grid item md={6} xs={12}>
                        <TextField
                            disabled={isLoading}
                            fullWidth
                            label={literals("sign_up.form.first_name")}
                            required={true}
                            name="firstName"
                            error={firstName.error !== ""}
                            helperText={firstName.error}
                            value={firstName.value}
                            onChange={handleChange}
                            {...customProps.firstName}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <TextField
                            name="lastName"
                            disabled={isLoading}
                            fullWidth
                            label={literals("sign_up.form.last_name")}
                            required={true}
                            error={lastName.error !== ""}
                            helperText={lastName.error}
                            value={lastName.value}
                            onChange={handleChange}
                            {...customProps.lastName}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <EmailField
                            name="email"
                            disabled={isLoading}
                            fullWidth
                            label={literals("sign_up.form.email")}
                            required={true}
                            error={email.error !== ""}
                            helperText={email.error}
                            value={email.value}
                            onChange={handleChange}
                            {...customProps.email}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <PasswordField
                            name="password"
                            disabled={isLoading}
                            fullWidth
                            label={literals("sign_up.form.password")}
                            required={true}
                            error={password.error !== ""}
                            helperText={password.error}
                            value={password.value}
                            onChange={handleChange}
                            {...customProps.password}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <PasswordField
                            name="confirmPassword"
                            disabled={isLoading}
                            fullWidth
                            label={literals("sign_up.form.confirm_password")}
                            required={true}
                            error={confirmPassword.error !== ""}
                            helperText={confirmPassword.error}
                            value={confirmPassword.value}
                            onChange={handleChange}
                            {...customProps.password}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <PhoneField
                            label={literals("sign_up.form.phone_number")}
                            error={phoneNumber!.error !== ""}
                            value={phoneNumber!.value || ""}
                            name="phoneField"
                            onChange={handlePhoneNumberChange}
                            disabled={isLoading}
                            {...customProps.phone}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <SpinnerButton
                            title={literals("sign_up.form.submit")}
                            isLoading={isLoading}
                            onClick={submitUser}
                            {...customProps.submitButton}
                        />
                    </Grid>
                </Grid>
                {renderReCaptcha()}

                <DialogCloseButton onClick={handleClose} />

                <Divider />
                <Typography variant="subtitle1" className={classes.signUpOr}>
                    {literals("sign_in.OR")}
                </Typography>
                <Typography component="div" className={classes.signInGoogleButton}>
                    <GoogleLogin
                        clientId={config.googleLogin.clientId!}
                        buttonText={literals("sign_up.with_google")}
                        onSuccess={(response) => handleGoogleResponse(response)}
                        onFailure={() => handleGoogleFailureResponse}
                        cookiePolicy={"single_host_origin"}
                        isSignedIn={false}
                    />
                </Typography>
            </DialogContent>
        </Dialog>
    );
};

type DialogTitleProps = {
    onClick: () => void;
    children: JSX.Element[];
};

const DialogTitle = (props: DialogTitleProps) => {
    const classes = useStyles();
    return <div className={classes.dialogTitle}>{props.children}</div>;
};

export { SignUpForm };
