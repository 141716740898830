import _groupBy from "lodash/groupBy";
import { PayForthRequest } from "$models/PayForth";
import { useHistory } from "react-router";
import { authenticationService } from "$services/core";
import { engine, payment } from "$config/config";
import { usePaymentResponse } from "$hooks/PaymentResponse";
import { IAmountState } from "./IAmountState";
import { useCheckoutDataForPayment } from "../useCheckoutDataForPayment";
import { SendTicketsTo } from "@djordjeandjelkovic/medgress_common_react_modules";

const usePayFortPayment = (eventId: string, ticketsTo: SendTicketsTo): IAmountState => {
    const history = useHistory();
    const { isSuccess, error } = usePaymentResponse();

    const userEmail = authenticationService.getUser()!.email;

    const { cartId, tickets, currency, total, totalWithDecimalPoint } = useCheckoutDataForPayment(eventId);

    const signature = PayForthRequest.createSigniture({
        accessCode: payment.accessCode,
        merchantIdentifier: payment.mIdentifier,
        merchantReference: cartId!,
        language: "en",
        returnUrl: `${engine.apiUrl}/ticket-carts/${cartId}/payment?sendTicketsTo=${ticketsTo}`,
        merchantExtra: `${history.location.pathname}`,
        command: "PURCHASE",
        amount: totalWithDecimalPoint,
        currency,
        customerEmail: userEmail,
    });

    const ticketsGroupedById = _groupBy(tickets, (ticket) => ticket.ticket.id);

    return {
        tickets: ticketsGroupedById,
        total,
        totalWithDecimalPoint,
        currency,
        returnUrl: `${engine.apiUrl}/ticket-carts/${cartId}/payment?sendTicketsTo=${ticketsTo}`,
        merchantExtra: `${history.location.pathname}`,
        signature,
        isSuccessPayment: isSuccess,
        paymentMessage: error,
        reference: cartId!,
        email: userEmail,
    };
};

export { usePayFortPayment };
