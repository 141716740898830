export class EventFilterDTO {
    name?: string;
    city?: string;
    natureType?: string;
    eventType?: string;
    startDate: Date;

    constructor(startDate: Date, name?: string, city?: string, natureType?: string, eventType?: string) {
        this.name = name;
        this.city = city;
        this.natureType = natureType;
        this.eventType = eventType;
        this.startDate = startDate;
    }

    static Default() {
        return new EventFilterDTO(new Date(), "", "", "-1", "-1");
    }
}