import { useState } from "react";
import _groupBy from "lodash/groupBy";
import { useCheckoutDataForPayment } from "../useCheckoutDataForPayment";
import { ticketCartService } from "$services/core";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { useMessage } from "$helper/useMessage";

export const useTotalInformation = (cartId: string, eventId: string) => {
    const { tickets, currency, total, isFree } = useCheckoutDataForPayment(eventId);
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState<boolean>();
    const { showError } = useMessage();
    const getLiteral = useLiterals();

    const handleFinish = async () => {
        try {
            setLoading(true);
            await ticketCartService.finishProcessCart(cartId!);
            setSuccess(true);
        } catch (error) {
            console.error(error);
            showError(getLiteral("checkout.error_message"));
        }
        finally{
            setLoading(false);
        }
    };

    const ticketsGroupedById = _groupBy(tickets, (ticket) => ticket.ticket.id);
    return {
        tickets: ticketsGroupedById,
        total,
        currency,
        isLoading,
        isFree,
        isSuccess,
        onFinish: handleFinish,
    };
};
