import React from "react";

import { events } from "$config/config";
import { useLiterals } from "$hooks/Translate/useLiterals";
import Button from "@material-ui/core/Button";
import { EMBEDDED } from "$enums/Embedded";
import { useStyles } from "./styles";

type AddToCartButtonProps = {
    onAddToCart: (id: string) => void;
    disabled: boolean;
    isLoading: boolean;
    id: string;
    embeded?: EMBEDDED;
    acronym: string;
    hidden: boolean;
};

export default (props: AddToCartButtonProps) => {
    const { onAddToCart, isLoading, disabled, id, embeded, acronym, hidden } = props;
    const classes = useStyles();
    const getLiteral = useLiterals();

    const buttonProps: { [key: number]: any } = {
        [EMBEDDED.FALSE]: {
            className: classes.addToCart,
            onClick: () => onAddToCart(id),
            disabled: disabled || isLoading,
            color: "primary",
            variant: "contained",
        },
        [EMBEDDED.TRUE]: {
            className: classes.addToCart,
            disabled: disabled || isLoading,
            color: "primary",
            target: "_blank",
            href: `${events.url}/events/${acronym}`,
            variant: "contained",
        },
    };

    return hidden ? null : (
        <Button {...buttonProps[embeded || EMBEDDED.FALSE]}>
            {getLiteral("event_details.ticket.button.add_to_cart")}
        </Button>
    );
};
