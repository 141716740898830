import { useMemo } from "react";
import { ITicketCount } from "./useAddTickets";
import {
    AttendeeBatchDTO,
    attendeeToAttendeeFieldDTO,
    EventDTO,
    IError,
} from "@djordjeandjelkovic/medgress_common_react_modules";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { useMessage } from "$helper/useMessage";
import { utils } from "$helper/utils";
import { attendeeService, authenticationService, ticketCartService } from "$services/core";
import { TicketCart } from "$models/types";
import {
    AttendeeField,
    ErrorType,
    IAttendee,
    IAttendeeField,
} from "@djordjeandjelkovic/medgress_common_react_modules";
import _map from "lodash/map";
import _cloneDeep from "lodash/cloneDeep";
import { useTicketCartSelector } from "$redux/selectors";
import { useCart } from "./useCart";
import { TicketsCount } from "./useTicketsCount";

type CreateTicketsProps = {
    ticketTypes: TicketsCount;
    event: EventDTO;
};
export const useCreateTickets = ({ ticketTypes, event }: CreateTicketsProps) => {
    const { showError } = useMessage();
    const getLiteral = useLiterals();
    const { cartId, ticketsInCart } = useTicketCartSelector(event.id);
    const cart = useCart();

    const user = useMemo(() => authenticationService.getUser(), []);

    const _createCart = async () => {
        if (cartId) {
            return cartId;
        }
        const c = await cart.create(event.id, user?.id);

        return c?.id;
    };

    const createTickets = async () => {
        const ticketsForCart: TicketCart[] = [];

        await _deleteTickets();

        const cId = await _createCart();
        const fields = await attendeeService.getAttendeeFieldsWithError(event.id);

        const ticketsForBatch: AttendeeBatchDTO[] = [];

        ticketTypes.tickets.forEach((ticketType: ITicketCount) => {
            for (let i = 0; i < ticketType.count; i++) {
                ticketsForBatch.push(
                    _createAttendeeBatch(
                        fields,
                        cId!,
                        ticketType.ticket.price?.id!,
                        ticketType.ticket.id,
                        event.id
                    )
                );
            }
        });

        const reservedTickets = await ticketCartService.addTicketsToCart(ticketsForBatch);

        reservedTickets.forEach((rTicket) => {
            const t = ticketTypes.find((e) => e.ticket.price!.id === rTicket.priceId);
            const attendeeForTicket = _createTicketInCart(_cloneDeep(fields), event.id);
            attendeeForTicket.id.value = rTicket.attendee?.id;
            ticketsForCart.push({
                ticket: t!.ticket,
                event,
                attendee: attendeeForTicket,
            });
        });

        return ticketsForCart;
    };

    const createTicketOfType = async (id: string) => {
        const ticketsForCart: TicketCart[] = [];

        const cId = await _createCart();

        await utils.forEachAsync(ticketTypes.tickets, async (ticketType: ITicketCount) => {
            if (ticketType.ticket.id !== id) {
                return;
            }

            for (let i = 0; i < ticketType.count; i++) {
                const attendee = await _createAttendeeForTicket(
                    ticketType.ticket.price?.id!,
                    event.id,
                    cId!,
                    ticketType.ticket.id
                );
                if (attendee) {
                    ticketsForCart.push({
                        ticket: ticketType.ticket,
                        event,
                        attendee,
                    });
                }
            }
        });

        return ticketsForCart;
    };

    const _createAttendeeBatch = (
        fields: IError<IAttendeeField>[],
        cartId: string,
        priceId: string,
        ticketId: string,
        eventId: string
    ): AttendeeBatchDTO => {
        const attendee = _createTicketInCart(fields, eventId);
        return {
            cartId,
            priceId,
            ticketId,
            attendee: attendeeToAttendeeFieldDTO(_toIAttendee(attendee)),
        };
    };

    const _createAttendeeForTicket = async (
        priceId: string,
        eventId: string,
        cartId: string,
        ticketId: string
    ) => {
        try {
            const attendee = _createTicketInCart(
                await attendeeService.getAttendeeFieldsWithError(eventId),
                eventId
            );
            const response = await ticketCartService.addAttendeeToCart(
                cartId,
                eventId,
                priceId,
                ticketId,
                _toIAttendee(attendee)
            );
            attendee.id.value = response.id!;
            return attendee;
        } catch (error) {
            console.error(error);
            showError(getLiteral(`engine.response.${error}`));
        }

        return null;
    };

    const _deleteTickets = async () => {
        if (!ticketsInCart[event.id]) {
            return;
        }
        await utils.forEachAsync(ticketsInCart[event.id].tickets, async (ticket) => {
            try {
                await ticketCartService.removeAttendeeFromCart(
                    ticketsInCart[event.id].cartId!,
                    ticket.attendee!.id.value
                );
            } catch (error) {
                showError(getLiteral("checkout.error_message"));
                console.error("Problem with removing attendee.", error);
            }
        });
    };

    return { createTickets, createTicketOfType };
};

const _createTicketInCart = (
    attendeeFields: IError<AttendeeField>[],
    eventId: string
): ErrorType<IAttendee, ""> => {
    return {
        id: { value: "" },
        firstName: { value: "" },
        lastName: { value: "" },
        email: { value: "" },
        eventId: { value: eventId },
        fields: attendeeFields,
    };
};

const _toIAttendee = (attendee: ErrorType<IAttendee, ""> | undefined): IAttendee => ({
    id: attendee?.id?.value || null,
    firstName: attendee?.firstName?.value || "",
    lastName: attendee?.lastName?.value || "",
    email: attendee?.email?.value || "",
    eventId: attendee?.eventId?.value || "",
    fields: _map(attendee?.fields || [], (field) => field.value),
});
