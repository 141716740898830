import { CartAction, SET_ID } from "$redux/actions/Cart/cartActionsTypes";
import { TicketsState } from "./ticketsCartReducer";

export const cartReducer = (state: TicketsState, action: CartAction): TicketsState => {
    switch (action.type) {
        case SET_ID:
            return setId(state, action);
        default:
            return state;
    }
};

const setId = (
    state: TicketsState,
    action: {
        id: string | null;
        eventId: string;
    }
) => {
    if (!action.id) {
        return removeEventFromCart(state, action.eventId);
    }
    return {
        ...state,
        [action.eventId]: { ...state[action.eventId], cartId: action.id },
    };
};

const removeEventFromCart = (state: TicketsState, eventId: string) => {
    delete state[eventId];
    return { ...state };
};

