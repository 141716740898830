import _map from "lodash/map";
import * as api from "$helper/api";

import {
    IAttendeeField,
    attendeeFieldDTOToIAttendeeField,
    AttendeeFieldDTO,
    IAttendee,
    AttendeeDTO,
    IError,
    ErrorType,
    AttendeeDTOMapper,
} from "@djordjeandjelkovic/medgress_common_react_modules";

const getAttendeeFieldsWithError = async (eventId: string): Promise<IError<IAttendeeField>[]> => {
    const data = await api.get<AttendeeFieldDTO[]>(`/fields/events/${eventId}`);
    return _map(await attendeeFieldDTOToIAttendeeField(data), (attendee) => ({
        value: attendee,
        error: false,
        message: "",
    }));
};

const getAttendeeFields = async (eventId: string): Promise<IAttendeeField[]> => {
    const data = await api.get<AttendeeFieldDTO[]>(`/fields/events/${eventId}`);
    return attendeeFieldDTOToIAttendeeField(data);
};

const get = async (
    attendeeId: string,
    optionals?: { date: string; hash: string }
): Promise<ErrorType<IAttendee, "">> => {
    const data = await api.get<AttendeeDTO>(
        `/attendees/${attendeeId}?` +
            (optionals && optionals.date && `d=${optionals.date}`) +
            (optionals && optionals.hash && `&h=${optionals.hash}`)
    );
    const fields = await getAttendeeFields(data.eventId!);
    return AttendeeDTOMapper.toErrorIAttendee(data, fields);
};

const update = async (
    attendee: ErrorType<IAttendee, "">,
    optionals?: { date: string; hash: string }
): Promise<void> => {
    return api.put(
        `/attendees/${attendee.id.value}?` +
            (optionals && optionals.date && `d=${optionals.date}`) +
            (optionals && optionals.hash && `&h=${optionals.hash}`),
        AttendeeDTOMapper.toAttendee(attendee)
    );
};

export const attendeeService = {
    getAttendeeFieldsWithError,
    getAttendeeFields,
    get,
    update,
};
