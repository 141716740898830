import React from "react";
import { EventInformation } from "$pages/EventDetails/EventInformation";
import { useEventDetailsState } from "$pages/EventDetails/useEventDetailsState";
import { useParams } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import { EventDetailsLoader } from "$components/ContentLoader";
import { PageHeader } from "$components/PageHeader";
import { Tickets } from "$pages/EventDetails/Tickets";

export const Large = () => {
    const { acronym } = useParams<{ acronym: string }>();
    const { event, eventQRCode } = useEventDetailsState({ acronym });

    if (!event) {
        return (
            <Container>
                <EventDetailsLoader />
            </Container>
        );
    }

    return (
        <div>
            <PageHeader
                title={event.coverImage ? "" : event.name}
                subtitle={event.coverImage ? "" : event.acronym}
                imgSrc={event.coverImage}
                removeHeader={true}
            />
            <Container>
                <Grid container spacing={3}>
                    <Grid item md={8} xs={12}>
                        <EventInformation details={event} eventQRCode={eventQRCode} />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Tickets acronym={acronym} event={event} embeded />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};
