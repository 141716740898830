import _ from "lodash";

export class PayForthStatus {
    statusCode!: number;
    description!: string;

    static getDescription(statusCode: number) {
        const status = _.find(PayForthStatus.statuses, (status) => status.statusCode === statusCode);

        return status?.description;
    }

    static statuses: PayForthStatus[] = [
        { statusCode: 0, description: "Invalid Request." },
        { statusCode: 1, description: "Order Stored." },
        { statusCode: 2, description: "Authorization Success." },
        { statusCode: 3, description: "Authorization Failed." },
        { statusCode: 4, description: "Capture Success." },
        { statusCode: 5, description: "Capture failed." },
        { statusCode: 6, description: "Refund Success." },
        { statusCode: 7, description: "Refund Failed." },
        { statusCode: 8, description: "Authorization Voided Successfully." },
        { statusCode: 9, description: "Authorization Void Failed." },
        { statusCode: 10, description: "Incomplete." },
        { statusCode: 11, description: "Check status Failed." },
        { statusCode: 12, description: "Check status success." },
        { statusCode: 13, description: "Purchase Failure." },
        { statusCode: 14, description: "Purchase Success." },
        { statusCode: 15, description: "Uncertain Transaction." },
        { statusCode: 17, description: "Tokenization failed." },
        { statusCode: 18, description: "Tokenization success." },
        { statusCode: 19, description: "Transaction pending." },
        { statusCode: 20, description: "On hold." },
        { statusCode: 21, description: "SDK Token creation failure." },
        { statusCode: 22, description: "SDK Token creation success." },
        { statusCode: 23, description: "Failed to process Digital Wallet service." },
        { statusCode: 24, description: "Digital wallet order processed successfully." },
        { statusCode: 27, description: "Check card balance failed." },
        { statusCode: 28, description: "Check card balance success." },
        { statusCode: 29, description: "Redemption failed." },
        { statusCode: 30, description: "Redemption success." },
        { statusCode: 31, description: "Reverse Redemption transaction failed." },
        { statusCode: 32, description: "Reverse Redemption transaction success." },
        { statusCode: 40, description: "Transaction In review." },
        { statusCode: 42, description: "Currency conversion success." },
        { statusCode: 43, description: "Currency conversion failed." },
        { statusCode: 44, description: "3ds success." },
        { statusCode: 45, description: "3ds failed." },
        { statusCode: 46, description: "Bill creation success." },
        { statusCode: 47, description: "Bill creation failed." },
        { statusCode: 48, description: "Generating invoice payment link success." },
        { statusCode: 49, description: "Generating invoice payment link failed." },
        { statusCode: 50, description: "Batch file upload successfully." },
        { statusCode: 51, description: "Upload batch file failed." },
        { statusCode: 52, description: "Token created successfully." },
        { statusCode: 53, description: "Token creation failed." },
        { statusCode: 54, description: "Get Tokens Success." },
        { statusCode: 55, description: "Get Tokens Failed." },
        { statusCode: 56, description: "Reporting Request Success." },
        { statusCode: 57, description: "Reporting Request Failed." },
        { statusCode: 58, description: "Token updated successfully." },
        { statusCode: 59, description: "Token updated failed." },
        { statusCode: 62, description: "Get Installment Plans Successfully." },
        { statusCode: 63, description: "Get Installment plans Failed." },
        { statusCode: 66, description: "Delete Token Success." },
        { statusCode: 70, description: "Get batch results successfully." },
        { statusCode: 71, description: "Get batch results failed." },
        { statusCode: 72, description: "Batch processing success." },
        { statusCode: 73, description: "Batch processing failed." },
        { statusCode: 74, description: "Bank transfer successfully." },
        { statusCode: 75, description: "Bank transfer failed." },
        { statusCode: 76, description: "Batch validation successfully." },
        { statusCode: 77, description: "Batch validation failed." },
        { statusCode: 80, description: "Credit card verified successfully." },
        { statusCode: 81, description: "Failed to verify credit card." },
    ];
}
