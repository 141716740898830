import React, { useState, MouseEvent } from "react";

import { Divider, MenuList, Paper, Popover, IconButton } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";

import { LogoutMenuItem } from "./LogoutMenuItem";
import { UserProfileMenuItem } from "./UserProfileMenuItem";
import { HistoryPurchaseMenuItem } from "./HistoryPurchaseMenuItem";

interface ProfileProps {
    onClose: () => void;
}

const ProfilePopup = (props: ProfileProps) => {
    const { onClose } = props;

    return (
        <Paper>
            <MenuList>
                <UserProfileMenuItem onClick={onClose} />
                <HistoryPurchaseMenuItem onClick={onClose} />
                <Divider />
                <LogoutMenuItem onClick={onClose} />
            </MenuList>
        </Paper>
    );
};

interface UserProfilePopperProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
}

const UserProfilePopper = (props: UserProfilePopperProps) => {
    const { open, anchorEl, onClose } = props;
    
    return (
        <Popover
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
        >
            <ProfilePopup onClose={onClose} />
        </Popover>
    );
};

const UserProfile = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClosePopper = () => {
        setAnchorEl(null);
    };

    const handleAvatarClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget));
    };

    return (
        <>
            <IconButton color="inherit" onClick={handleAvatarClick}>
                <AccountCircle />
            </IconButton>
            <UserProfilePopper open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClosePopper} />
        </>
    );
};

export { UserProfile };
