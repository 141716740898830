import en from "./language.en";
import Moment from "moment";

/**
 * @param {number} value A value to format
 * @param {number} [totalDecimals] total number of decimals to show (defaults to 2)
 * @param {string} [groupSeparator] defaults to ","
 * @param {string} [decimalSeparator] defaults to "."
 * @param {string} [positivePrefix] defaults to ""
 * @param {string} [negativePrefix] defaults to "-"
 * @returns {string} Formatted amount with currency
 */
const formatAmount = (value: number, totalDecimals = 2, groupSeparator = ",", decimalSeparator = ".", positivePrefix = "", negativePrefix = "-" ): string => {
    // Find groups that have total of 3 numerals
    const re = /\d(?=(\d{3})+\D)/g;
    // Get absolute value of the number to add negative/positive sign manually from
    // the parameters and fix decimal points
    const strValue = Math.abs(value).toFixed(Math.max(0, Number(totalDecimals)));
    const sign = value >= 0 ? positivePrefix : negativePrefix;
    const number = strValue
        // replace default decimal separator "." with our defined one
        .replace(".", decimalSeparator)
        // after every captured group of 3 numerals
        .replace(re, "$&" + groupSeparator);
    return `${sign}${number}`;
}

/**
 * @param {*} value
 * @param {function(...[*]):string} literals
 * @returns {string|*} Formatted date to longDate format as defined in literals
 */
const toLongDateString = (value: string, literals: any): string => {
    if (Moment.isMoment(value)) {
        return value.format(literals("localeFormat.longDate"));
    } else {
        if (Moment(value).isValid()) {
            return Moment(value).format(literals("localeFormat.longDate"));
        } else {
            return literals("localeFormat.invalidDate");
        }
    }
};

/**
 * @param {*} value
 * @returns {string|*} Formatted date to ISO
 */
const toIsoDateString = (value: string): string => {
    if (Moment.isMoment(value)) {
        return value.format("YYYY-MM-DD");
    } else if (Moment.isDate(value)) {
        return value.toISOString().substring(0, 10);
    } else {
        return Moment(value).format("YYYY-MM-DD");
    }
};

/**
 * Creates a literals object that can be used to translate translation keys to localized strings.
 *
 * @param localeMap A key value object containing keys and translations in tree structure
 * @returns {function(...[*]):string}
 */
const createLiterals = (localeMap: { [key: string]: string }) => {
    return (key: string, ...args: any[]): string => {
        const value = localeMap[key] || en[key];

        if (value == null) return key;
        if (!value) return value;
        if (args.length === 0) return value;

        return args.reduce(
            (currentValue, arg, index) => currentValue.toString().replace(new RegExp(`{${index}\\}`, "g"), arg),
            value
        );
    };
};

export { formatAmount, toIsoDateString, toLongDateString, createLiterals };
