import * as React from "react";

import { Button, Container, Grid, Link, Theme, Typography, useMediaQuery } from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import BusinessIcon from "@material-ui/icons/Business";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { Copyright } from "$components/core";
import { centra } from "$config/config";

import { styles } from "./styles";
import clsx from "clsx";
import { useLiterals } from "$hooks/Translate/useLiterals";

const Footer = () => {
    const classes = styles();
    const isLowerThenSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
    const getLiteral = useLiterals();

    return (
        <>
            <Grid className={classes.root}>
                <Container>
                    <Grid container spacing={4}>
                        <Grid item md={3} xs={12} className={classes.footerItem}>
                            <Typography variant="h5">
                                {getLiteral("footer.app_name")}
                            </Typography>
                            <Typography variant="body2">
                                {getLiteral("footer.app_description")}
                            </Typography>
                        </Grid>

                        <Grid item md={3} xs={12} className={classes.footerItem}>
                            <Typography variant="body1">
                                {getLiteral("footer.tickets_hotline.title")}
                            </Typography>
                            <Typography variant="h5">
                                +971 50 9299239
                            </Typography>
                            <Typography variant="body2">
                                {getLiteral("footer.tickets_hotline.working_hours")}
                            </Typography>
                        </Grid>

                        <Grid item md={3} xs={12} className={classes.footerItem}>
                            <Typography variant="body1">
                                {getLiteral("footer.need_help.title")}
                            </Typography>
                            <Button variant="outlined">{getLiteral("footer.need_help.button")}</Button>
                        </Grid>

                        <Grid item md={3} xs={12} className={classes.footerItem}>
                            <Typography variant="body1">
                                {getLiteral("footer.event_organizer.title")}
                            </Typography>
                            <Button variant="contained" color="primary" href={centra.url}>
                                {getLiteral("footer.event_organizer.button")}
                            </Button>
                            <Typography variant="body2">
                                {getLiteral("footer.event_organizer.description")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item md={4} xs={12} className={classes.footerItem}>
                            <Typography variant="h6" className={classes.header}>
                                CONTACT US
                            </Typography>

                            <ul className={clsx(classes.list, { [classes.listCenter]: isLowerThenSmall })}>
                                <li className={classes.listItem}>
                                    <BusinessIcon className={classes.icon} /> Diaedu Management Consultancy
                                </li>
                                <li className={classes.listItem}>
                                    <PhoneIcon className={classes.icon} /> +971 4 4532975
                                </li>
                                <li className={classes.listItem}>
                                    <LocationOnIcon className={classes.icon} /> 907 Dusseldorf Business Point,
                                    Al Barsha 1, Dubai, United Arab Emirates
                                </li>
                                <li className={classes.listItem}>
                                    <MailOutlineIcon className={classes.icon} /> contact [at] diaedu.com
                                </li>
                            </ul>
                        </Grid>

                        <Grid item md={4} xs={12} className={classes.footerItem}>
                            <Grid className={classes.footerNetwork}>
                                <Typography variant="h6" className={classes.header}>
                                    FOLLOW US
                                </Typography>

                                <Link
                                    href={"https://www.facebook.com/diaedullc/"}
                                    className={classes.link}
                                    target="_blank"
                                    rel="noopener">
                                    <FacebookIcon fontSize="large" className={classes.icon} />
                                </Link>

                                <Link
                                    href={"https://www.linkedin.com/company/diaedu"}
                                    className={classes.link}
                                    target="_blank"
                                    rel="noopener">
                                    <LinkedInIcon fontSize="large" className={classes.icon} />
                                </Link>

                                <Link
                                    href={"https://twitter.com/diaedumc?lang=en"}
                                    className={classes.link}
                                    target="_blank"
                                    rel="noopener">
                                    <TwitterIcon fontSize="large" className={classes.icon} />
                                </Link>
                            </Grid>
                        </Grid>

                        <Grid item md={4} xs={12} className={classes.footerItem}>
                            <Typography variant="h6" className={classes.header}>
                                QUICK LINKS
                            </Typography>

                            <ul className={clsx(classes.list, { [classes.listCenter]: isLowerThenSmall })}>
                                <li className={classes.listItem}>
                                    <Link href={"/events"} className={classes.link}>
                                        Events
                                    </Link>
                                </li>
                                <li className={classes.listItem}>
                                    <Link href={"/terms-and-conditions"} className={classes.link}>
                                        Terms & Conditions
                                    </Link>
                                </li>
                                <li className={classes.listItem}>
                                    <Link href={"/contact-us"} className={classes.link}>
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Copyright />
        </>
    );
};

export { Footer };
