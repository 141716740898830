import { useState } from "react";

import ticketCartService from "$services/ticketcart.service";

import { useMessage } from "$helper/useMessage";

type UseTicketFooterActionsProps = {
    cartId: string;
    attendeeId: string;
}

const useTicketFooterActions = (props: UseTicketFooterActionsProps) => {
    const { cartId, attendeeId } = props;
    const [isLoadingResend, setLoadingResend] = useState(false);

    const { showError, showSuccess } = useMessage();

    const handleResendTicket = async () => {
        setLoadingResend(true);
        try {
            await ticketCartService.resendTicket(cartId, attendeeId);
            showSuccess("Ticket has been resent. Please, check your e-mail.");
        } catch (error) {
            showError("Error while resending ticket. Try again later.");
            console.error(error);
        } finally {
            setLoadingResend(false);
        }
    };

    return [{
        isLoadingResend
    }, {
        handleResendTicket
    }];
}

export default useTicketFooterActions;
