import { useDispatch, useSelector } from "react-redux";
import * as TicketsCartActionCreators from "$redux/actions/TicketsCart/ticketsCartActions";
import { bindActionCreators } from "redux";

//types
import { ReduxStore } from "$redux/reducers";
import { TicketCart } from "@djordjeandjelkovic/medgress_common_react_modules";

const useTicketsCart = (eventId: string | null): [TicketCart[], typeof TicketsCartActionCreators] => {
    const dispatch = useDispatch();
    const tickets = useSelector<ReduxStore, TicketCart[]>((store) =>
        eventId == null ? [] : store.ticketsCart[eventId]?.tickets
    );

    const boundActionCreators = bindActionCreators(TicketsCartActionCreators, dispatch);

    return [tickets, boundActionCreators];
};

export { useTicketsCart };
