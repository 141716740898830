import { SET_LITERALS, LiteralAction } from "$redux/actions";
import { getLiterals } from "../../utilities/locale";

const DEFAULT_LANGUAGE = "en";

const InitialState = {
    literals: getLiterals(DEFAULT_LANGUAGE),
    language: DEFAULT_LANGUAGE
};

export const literalReducer = (state = InitialState, action: LiteralAction) => {
    switch (action.type) {
        case SET_LITERALS:
            return {
                ...state,
                literals: getLiterals(action.language),
                language: action.language
            };
        default:
            return state;
    }
}