import React from "react";

import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

type RemoveButtonProps = {
    id: string;
    eventId: string;
    onClick: (id: string, eventId: string) => void;
};

export const RemoveButton = (props: RemoveButtonProps) => {
    const { id, eventId, onClick } = props;

    return (
        <IconButton onClick={(e) => onClick(id, eventId)}>
            <CloseIcon />
        </IconButton>
    );
};
