import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Theme, useMediaQuery } from "@material-ui/core";
import { useStyles } from "./styles";

type DialogCloseButtonProps = {
    onClick: () => void;
};
export const DialogCloseButton = (props: DialogCloseButtonProps) => {
    const { onClick } = props;
    const classes = useStyles();
    const isSmallerThenSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    if (!isSmallerThenSm) return null;

    return (
        <Button className={classes.button} onClick={onClick}>
            <CloseIcon />
        </Button>
    );
};
