import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { compose, withProps } from "recompose";

type GoogleMapProps = {
    isMarkerShown: boolean;
    latitude: number;
    longitude: number;
}

const GoogleMaps = (props: GoogleMapProps) => {
        const { isMarkerShown, latitude, longitude } = props;

        return (
            <GoogleMap defaultZoom={8} defaultCenter={{ lat: latitude, lng: longitude }}>
                {isMarkerShown && <Marker position={{ lat: latitude, lng: longitude }} />}
            </GoogleMap>
        );
}

const _GoogleMaps = compose<GoogleMapProps, GoogleMapProps>(
    withProps({
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyDZHQCMj3YrlWNG5DF5GugA2bNNHcXEnw0&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `450px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) => (
    <GoogleMaps latitude={props.latitude} longitude={props.longitude} isMarkerShown={props.isMarkerShown} />
));

export { _GoogleMaps as GoogleMaps };
