import { useCheckoutSelectors, useTicketCartSelector } from "$redux/selectors";
import CurrencyDecimalPointMultiplayer from "./currency-decimal-point";
import { TicketCart } from "$models/types";
import _reduce from "lodash/reduce";

const useCheckoutDataForPayment = (eventId: string) => {
    const { cartId } = useTicketCartSelector(eventId);
    const { tickets } = useCheckoutSelectors(eventId);

    const currency = getCurrency(tickets);
    const total = calculateTotal(tickets);
    const totalWithDecimalPoint = formatTotalToCurrencyFormat(total, currency);

    return { cartId, tickets, currency, total, totalWithDecimalPoint, isFree: total === 0 };
};

const formatTotalToCurrencyFormat = (total: number, currency: string) => {
    return total * CurrencyDecimalPointMultiplayer[currency.toUpperCase()];
};

const getCurrency = (tickets?: TicketCart[]) => {
    let currency = "USD";
    if (!tickets) {
        return currency;
    }

    return tickets[0].ticket.currency || currency;
};

const calculateTotal = (tickets?: TicketCart[]) => {
    if (!tickets) return 0;
    return _reduce(tickets, (sum, t) => sum + (t.ticket.price?.amount || 0), 0);
};

export { useCheckoutDataForPayment };
