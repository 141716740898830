import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        descriptionContainer: {
            marginTop: 40,
            textAlign: "justify",
        },
        descriptionContainerPadding: {
            paddingTop: 40,
            paddingBottom: 40,
            paddingLeft: 80,
            paddingRight: 80,
        },
        descriptionPart: {
            padding: 20,
        },
        paragraph: {
            paddingTop: 10,
        },
    })
);
