import { IFormState } from "./useLogin";

export const SET_EMAIL = "Login.SET_EMAIL" as "Login.SET_EMAIL";
export const SET_PASSWORD = "Login.SET_PASSWORD" as "Login.SET_PASSWORD";

export type Action =
    | { type: typeof SET_EMAIL; email: string; error: string | null }
    | { type: typeof SET_PASSWORD; password: string; error: string | null };

export const initial = {
    email: { value: "", error: null },
    password: { value: "", error: null },
} as IFormState;

export function reducer(state: IFormState = initial, action: Action) : IFormState {
    switch (action.type) {
        case SET_PASSWORD:
            return { ...state, password: { value: action.password, error: action.error } };
        case SET_EMAIL:
            return { ...state, email: { value: action.email, error: action.error } };
    }
}
