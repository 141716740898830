import React from "react";
import _find from "lodash/find";
import _map from "lodash/map";
import _sortBy from "lodash/sortBy";
import _forEach from "lodash/forEach";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { ITicketCartPopper } from "@djordjeandjelkovic/medgress_common_react_modules";
import * as TicketsCartActionCreators from "$redux/actions/TicketsCart/ticketsCartActions";
import { useTicketCartSelector } from "$redux/selectors/useTicketCartSelector";
import { ticketCartService } from "$services/core";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { TicketsState } from "$redux/reducers/ticketsCartReducer";
import { useMessage } from "$helper/useMessage";
import { useCreditCardInfoState } from "$components/Payment/CreditCardInfo/creditCardInfoState";
import { TicketCart } from "$models/types";

interface ITicketCartState {
    expand: {
        id: string;
        expand: boolean;
    }[];
    cartTickets: ITicketCartPopper[];
}

interface ITicketCartActions {
    handleExpandClick: (id: string) => void;
    handleRemoveTicket: (id: string, eventId: string) => void;
    handleProceedCheckout: (eventId: string) => void;
}

const useTicketsCartState = ({
    onClose,
}: {
    onClose: () => void;
}): [ITicketCartState, ITicketCartActions] => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { ticketsInCart } = useTicketCartSelector(null);
    const getLiteral = useLiterals();
    const { showError } = useMessage();
    const [, creditCardState] = useCreditCardInfoState();
    const { removeTicket } = bindActionCreators(TicketsCartActionCreators, dispatch);

    const cartTickets = React.useMemo(() => {
        const ticketsGrouped: ITicketCartPopper[] = mapTicketStateToTicketCartPopper(ticketsInCart);

        _forEach(ticketsGrouped, (ticketGrouped) => {
            ticketGrouped.tickets = _sortBy(ticketGrouped.tickets, (ticket) => ticket.id);
        });

        return ticketsGrouped;
    }, [ticketsInCart]);

    const [expand, setExpand] = React.useState<{ id: string; expand: boolean }[]>([]);

    React.useEffect(
        () =>
            setExpand((prevState) => {
                const newTickets = _map(cartTickets, (tickets) => ({ id: tickets.event.id, expand: false }));
                _forEach(newTickets, (newTicket) => {
                    const isExpanded = _find(prevState, (ticket) => ticket.id === newTicket.id)?.expand;
                    newTicket.expand = isExpanded || false;
                });

                return newTickets;
            }),
        [cartTickets]
    );

    const handleExpandClick = (id: string) => {
        setExpand((prevState) => {
            const newState = [...prevState];
            const changeExpand = _find(newState, (expand) => expand.id === id);
            if (changeExpand) {
                changeExpand.expand = !changeExpand?.expand;
            }

            return newState;
        });
    };

    const handleRemoveTicket = async (id: string, eventId: string) => {
        const ticket = getTicket(id, eventId);
        if (!ticket) {
            return;
        }

        if (isLastTicket(eventId)) {
            creditCardState.reset();
        }

        if (!hasAttendee(ticket)) {
            removeTicket(ticket);
            return;
        }

        try {
            await ticketCartService.removeAttendeeFromCart(
                ticketsInCart[eventId].cartId!,
                ticket.attendee!.id.value
            );
        } catch (error) {
            showError(getLiteral("checkout.error_message"));
            console.error("Problem with removing attendee.", error);
        }

        removeTicket(ticket);
    };

    const getTicket = (id: string, eventId: string) => {
        return _find(ticketsInCart[eventId].tickets, (ticketInCart) => ticketInCart.ticket.id === id);
    };

    const isLastTicket = (eventId: string) => ticketsInCart[eventId].tickets.length === 1;

    const handleProceedCheckout = (eventId: string) => {
        onClose();
        history.push(`/checkout/event/${eventId}`);
    };

    return [
        { expand, cartTickets },
        { handleExpandClick, handleRemoveTicket, handleProceedCheckout },
    ];
};

const hasAttendee = (ticket: TicketCart) => ticket.attendee?.id.value;

const mapTicketStateToTicketCartPopper = (ticketsInCart: TicketsState) => {
    const ticketsGrouped: ITicketCartPopper[] = [];
    _forEach(ticketsInCart, (ticketInCart) => {
        _forEach(ticketInCart.tickets, (ticket) => {
            const currentTicket = _find(
                ticketsGrouped,
                (ticketInGrouped) => ticketInGrouped.event.id === ticket.event.id
            );

            if (!currentTicket) {
                ticketsGrouped.push({
                    event: {
                        icon: ticket.event.icon,
                        id: ticket.event.id,
                        name: ticket.event.name,
                    },
                    tickets: [
                        {
                            id: ticket.ticket.id,
                            type: ticket.ticket.title,
                            count: 1,
                            price: ticket.ticket.price,
                            currency: ticket.ticket.currency!,
                        },
                    ],
                });
            } else {
                const ticketGrouped = _find(
                    ticketsGrouped,
                    (ticketInGrouped) => ticketInGrouped.event.id === ticket.event.id
                );
                if (ticketGrouped) {
                    ticketGrouped.tickets.push({
                        id: ticket.ticket.id,
                        type: ticket.ticket.title,
                        count: 1,
                        price: ticket.ticket.price,
                        currency: ticket.ticket.currency!,
                    });
                }
            }
        });
    });

    return ticketsGrouped;
};

export { useTicketsCartState };
