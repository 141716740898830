import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        waitingWrapper: {
            height: "30vh",
            textAlign: "center",
        },
        centerMiddle: {
            lineHeight: "30vh",
        },
    })
);
