import { Theme, makeStyles, createStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme: Theme) => createStyles({
    wrapper: {
        position: "relative",
    },
    progress: {
        position: "absolute",
        top: "50%",
        left: "25%",
        marginTop: "-12px",
        color: grey[50],
    },
}));
