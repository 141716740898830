import * as api from "$helper/api";
import { Subscription } from "@djordjeandjelkovic/medgress_common_react_modules";

const get = (id: string) => {
    return api.get<string>(`/subscriptions/${id}`)
}

const list = () => {
    return api.get<string>(`/subscriptions`)
}

const insert = (subscription: Subscription) => {
    return api.post<Subscription, any>(`/subscriptions`, subscription);
}

const remove = (id: string) => {
    return api.remove<string>(`/subscriptions/${id}/`);
}

export const subscriptionService = {
    get,
    list,
    insert,
    remove
};
