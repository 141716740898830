import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
        },
        description: {
            minHeight: 450,
            textAlign: "justify",
            marginTop: 20,
        },
        labelValue: {
            fontWeight: "bold",
        },
        icon: {
            position: "relative",
            top: 10,
            left: 15,
        },
        eventName: {
            width: "100%",
            wordBreak: "break-all",
        },
        eventItem: {
            marginBottom: 15,
        }
    })
);
