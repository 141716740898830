import * as api from "$helper/api";
import { Announcement } from "@djordjeandjelkovic/medgress_common_react_modules";
import { announcement } from "$config/config";

const list = async (): Promise<Announcement[]> => {
    const announcements = await api.get<Announcement[]>(`/announcements/current?applicationId=${announcement.applicationId}`);
    
    let announcementList: Announcement[] = [];
    announcements.forEach((announcement) => {
        if (sessionStorage.getItem("announcement-" + announcement.id) == null) {
            announcementList.push(announcement);
        }
    });
    
    return announcementList;
}

export const announcementService = {
    list
};
