import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: 100000,
            color: 'black',
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            opacity: "0.5",
          },
          preview: {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              position: "fixed",
              fontSize: "50pt",
              zIndex: 1000,
              color: theme.palette.text.primary,
          }
    })
);