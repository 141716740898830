import { authenticationService } from "$services/core";
import { ResponseStatusCodeToken } from "$enums/ResponseStatusCodeToken";

export const handleResponse = async (response: Response) => {
    const text = await response.text();

    if (response.status === 404) {
        return Promise.reject(new Error("Resource not found. Please try again later."));
    }

    let data = null;
    try {
        data = text && JSON.parse(text, dateTimeReviver);
    } catch {
        return Promise.reject(new Error("Problem with connection. Please try again later."));
    }

    if (!response.ok) {
        if (response.status === 400 || response.status === 500) {
            return Promise.reject(
                (data && data.responseHeader && data.responseHeader.message) || "GENERAL_ENGINE_ERROR"
            );
        }

        if (isAnyOf([401, 403], response.status)) {
            try {
                data = text && JSON.parse(text, dateTimeReviver);
                return Promise.reject(data && data.responseHeader.message);
            } catch {
                return Promise.reject("Invalid response - status " + response.status);
            }
        }

        if (data.status === ResponseStatusCodeToken.TOKEN_EXPIRED) {
            await authenticationService.refreshToken();
        } else if (data.status === ResponseStatusCodeToken.MISSING_AUTHORIZATION_HEADER) {
            console.error(data.message);
            return Promise.reject(new Error(data.message));
        } else {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            authenticationService.logout();
            window.location.reload();
        }
    }

    if (typeof data.responseHeader !== "undefined" && data.responseHeader.status.toLowerCase() !== "ok") {
        const error = (data && data.responseHeader.message) || response.statusText;
        return Promise.reject(error);
    }

    return data.responseDetails;
};

const dateRegex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;

const dateTimeReviver = (_key: any, value: any) => {
    if (typeof value === "string") {
        const validDate = dateRegex.exec(value);
        if (validDate) {
            return new Date(
                Date.UTC(
                    +validDate[1],
                    +validDate[2] - 1,
                    +validDate[3],
                    +validDate[4],
                    +validDate[5],
                    +validDate[6]
                )
            );
        }
    }
    return value;
};

function isAnyOf(arr: number[], statusCode: number) {
    return arr.indexOf(statusCode) !== -1;
}

