import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import _take from "lodash/take";
import _map from "lodash/map";
import { EventDTO } from "@djordjeandjelkovic/medgress_common_react_modules";
import { Container, Paper, Grid, Typography, Button, useMediaQuery, Theme, Link } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import eventsService from "$services/events.service";
import { DecorativeLine, EventItemsContainerLoader, Tag } from "$components/core";
import { DateDefaultFormat } from "$constants/Dates";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { useStyles } from "./styles";
import DateRangeIcon from "@material-ui/icons/DateRange";
import utils from "$helper/utils";
import { getLocationInfo } from "src/utilities/eventUtils";

const EVENTS_PER_PAGE = 3;

const PopularEvents = () => {
    const [list, setList] = useState<EventDTO[] | null | undefined>(undefined);
    const classes = useStyles();
    const getLiteral = useLiterals();
    const [page, setPage] = useState(1);
    const isLowerThenSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    const handleChangePage = () => {
        setPage((page) => page + 1);
    };

    useEffect(() => {
        eventsService.getList().then((events) => {
            setList(events);
        });
    }, []);

    if (list && list.length === 0) {
        return <h1 className={classes.noEvents}>{getLiteral("events.event_list.no_events")}</h1>;
    }

    return (
        <Grid className={classes.root}>
            <Container>
                <Grid className={classes.headerContainer}>
                    <Typography variant="h3" className={classes.header}>
                        Popular <span className={classes.headerEvents}>Events</span>
                    </Typography>

                    <DecorativeLine show={!isLowerThenSmall} />
                </Grid>
                {!list ? 
                    <EventItemsContainerLoader />
                    :
                    <Grid container spacing={4}>
                        {_map(_take(list, page * EVENTS_PER_PAGE), (eventDetails) => {
                            return (
                                <Grid item lg={4} xs={12} key={"paper-" + eventDetails.acronym}>
                                    <Paper square={true} className={classes.listItemContainer}>
                                        <Grid
                                            item
                                            lg={12}
                                            className={classes.eventItemImage}
                                            style={{
                                                backgroundImage: `url(${eventDetails.coverImageSmaller})`,
                                            }}>
                                            <Link
                                                className={classes.eventBannerLink}
                                                href={"/events/" + eventDetails.acronym}
                                            >
                                                <span></span>
                                            </Link>
                                        </Grid>
                                        <Grid item lg={12} className={classes.eventItemDetailsRoot}>
                                            <Grid className={classes.eventItemDetailsContainer}>
                                                <Tag>{getLiteral(`events.event_type.${eventDetails.eventType}`)}</Tag>
                                                <Typography
                                                    variant="body2"
                                                    display="inline"
                                                    align="right"
                                                    className={classes.eventItemDateContainer}>
                                                    <DateRangeIcon className={classes.eventItemDateImage} />
                                                    <span className={classes.eventItemDate}>
                                                        {format(eventDetails.startDate, DateDefaultFormat)}
                                                    </span>
                                                </Typography>
                                                <Grid item lg={12}>
                                                    <Typography
                                                        gutterBottom
                                                        variant="h5"
                                                        className={classes.eventItemName}>
                                                        {utils.endWithThreeDot(eventDetails.name, 40)}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        className={classes.eventItemDescription}>
                                                        {eventDetails.description}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        className={classes.eventItemLocation}>
                                                        <LocationOnIcon
                                                            className={classes.eventItemLocationIcon}
                                                        />
                                                        {getLocationInfo(
                                                            eventDetails.location,
                                                            eventDetails.city,
                                                            eventDetails.countryId
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth={true}
                                                    href={"/events/" + eventDetails.acronym}>
                                                    {getLiteral("events.event_list.view_event")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            );
                        })}
                    </Grid>
                }

                <Grid className={classes.loadMoreContainer}>
                    {list != null && list !== undefined && list.length > page * EVENTS_PER_PAGE && (
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            className={classes.loadMore}
                            onClick={handleChangePage}>
                            {getLiteral("events.event_list.load_more")}
                        </Button>
                    )}
                </Grid>
            </Container>
        </Grid>
    );
};

export { PopularEvents };
