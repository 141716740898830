import { useState } from "react";

const useViewTicketPDFState = () => {
    const [pdfWidth, setPdfWidth] = useState(500);

    const increasePdfWidth = () => {
        setPdfWidth((prev) => prev + 100);
    }

    const reducePdfWidth = () => {
        setPdfWidth((prev) => prev - 100);
    }

    return [{
        pdfWidth
    }, {
        increasePdfWidth, reducePdfWidth
    }];
}

export default useViewTicketPDFState;