/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { IUser, toUser } from "@djordjeandjelkovic/medgress_common_react_modules";
import { userService } from "$services/core";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { useMessage } from "$helper/useMessage";

interface IState {
    user?: IUser;
    userHasPassword: boolean;
    oldPassword?: string;
    password?: string;
    confirmPassword?: string;
}

interface IActions {
    handleUpdateClick: (userUpdate: IUser) => void;
}

const useUserProfileState = (): [IState, IActions] => {
    const getLiteral = useLiterals();
    const { showError, showSuccess } = useMessage();

    const [user, setUser] = useState<IUser>();
    const [userHasPassword, setUserHasPassword] = useState<boolean>(false);

    useEffect(() => {
        userService.getUser()
        .then(userResponse => {
            setUser(toUser(userResponse));
        });
    }, []);

    useEffect(() => {
        const getHasPassword = async () => {
            const response = await userService.hasPassword();
            setUserHasPassword(response);
        };
        
        getHasPassword();
    }, []);

    const handleUpdateClick = (userUpdate: IUser) => {
        if (!userUpdate) {
            return;
        }

        userService.update(userUpdate).then(() => {
            showSuccess(getLiteral("user_profile.user_details.form.user_profile.update_info"));
            setUser(userUpdate);
        }).catch(error => {
            showError(getLiteral("user_profile.user_details.form.user_profile.update_error"));
            console.error(error);
        })
    };
    

    return [{ user, userHasPassword }, { handleUpdateClick }];
};

export { useUserProfileState };
