import { TicketCart } from "$models/types";

export const TICKET_ADD = "TICKET_ADD" as "TICKET_ADD";
export const TICKET_ADD_TYPE = "TICKET_ADD_TYPE" as "TICKET_ADD_TYPE";
export const TICKET_REMOVE = "TICKET_REMOVE" as "TICKET_REMOVE";
export const TICKET_REMOVE_ALL = "TICKET_REMOVE_ALL" as "TICKET_REMOVE_ALL";
export const TICKETS_ADD = "TICKETS_ADD" as "TICKETS_ADD";

export const TICKET = "TICKET" as "TICKET";

export type TicketAction =
    | { type: typeof TICKET_ADD; altType: typeof TICKET; ticket: TicketCart }
    | { type: typeof TICKET_ADD_TYPE; altType: typeof TICKET; tickets: TicketCart[] }
    | { type: typeof TICKET_REMOVE; altType: typeof TICKET; ticket: TicketCart }
    | { type: typeof TICKET_REMOVE_ALL; altType: typeof TICKET; eventId: string }
    | { type: typeof TICKETS_ADD; altType: typeof TICKET; tickets: TicketCart[] };
