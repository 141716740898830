import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: `${theme.typography.pxToRem(20)}`,
        },
    })
);

export { useStyles };
