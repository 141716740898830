import { useMessage } from "$helper/useMessage";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { ITicketCartDTO } from "@djordjeandjelkovic/medgress_common_react_modules";
import { ticketCartService } from "$services/core";
import * as CartActionCreator from "$redux/actions/Cart/cartActions";
import * as CartTimeoutActionCreators from "$redux/actions/CartTimeout/cartTimeoutActions";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

export const useCart = () => {
    const { showError } = useMessage();
    const getLiteral = useLiterals();
    const { setCartId, setCartIdForTimeout } = useActions();

    return {
        create: async (eventId: string, userId?: string) => {
            let ticketCart: ITicketCartDTO | null = null;
            try {
                ticketCart = await ticketCartService.createCartLoggedIn(userId);
                setCartId(ticketCart.id!, eventId);
                setCartIdForTimeout(eventId, ticketCart.id!);
            } catch (error) {
                showError(getLiteral("checkout.error_message"));
                console.error(error, "Problem with creating a cart");
            }
            return ticketCart;
        },
    };
};

const useActions = () => {
    const dispatch = useDispatch();
    const { setCartId } = bindActionCreators(CartActionCreator, dispatch);
    const { setCartIdForTimeout } = bindActionCreators(CartTimeoutActionCreators, dispatch);

    return { setCartId, setCartIdForTimeout };
};
