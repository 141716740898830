import { Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";

type TagProps = {
    children: React.ReactNode;
};
const Tag = (props: TagProps) => {
    const classes = useStyles();
    const { children } = props;
    return (
        <Typography
            variant="body2"
            display="inline"
            classes={{ root: classes.root }}
        >
            {children}
        </Typography>
    );
};

export { Tag };
