import * as React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, ButtonProps } from "@material-ui/core";

import { useStyles } from "./styles";

interface SpinnerButtonProps {
    isLoading: boolean;
    title: string;
    ButtonProps: ButtonProps;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const SpinnerButton = (props: SpinnerButtonProps) => {
    const { isLoading, title, onClick, ButtonProps } = props;
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Button {...ButtonProps} disabled={isLoading} onClick={onClick} fullWidth size="large">
                {title}
            </Button>
            {isLoading && <CircularProgress size={24} className={classes.progress} />}
        </div>
    );
};

export { SpinnerButton };
