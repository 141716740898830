import * as React from "react";
import { Typography, Grid, Container, useMediaQuery, Theme } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { PageHeader, ContactUs as ContactUsComponent, TopBorderContainer } from "$components/core";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { useStyles } from "./styles";
import clsx from "clsx";

type ContactUsProps = {
    eventId?: string;
};

const ContactUs = (props: ContactUsProps) => {
    const { eventId } = props;
    const classes = useStyles();
    const literals = useLiterals();
    const isLowerThenSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    return (
        <>
            <PageHeader title={literals("contact_us.title")} />
            <Container>
                <TopBorderContainer
                    className={clsx(classes.descriptionContainer, {
                        [classes.descriptionContainerPadding]: !isLowerThenSmall,
                    })}>
                    <Grid container>
                        <Grid item className={classes.contactPart}>
                            <Typography variant="h5">{literals("contact_us.company_name")}</Typography>

                            <Typography variant="body1" className={classes.description}>
                                {literals("contact_us.description")}
                            </Typography>
                        </Grid>
                        <Grid item lg={6} className={classes.contactPart}>
                            <ContactUsComponent eventId={eventId || ""} />
                        </Grid>
                        <Grid item lg={6} className={classes.contactPart}>
                            <Typography variant="h5" className={classes.findUs}>
                                {literals("contact_us.find_us")}
                            </Typography>
                            <Typography variant="body1">
                                <PhoneIcon className={classes.icon} />
                                <span>{literals("contact_us.phone")}</span>
                            </Typography>

                            <Typography variant="body1">
                                <LocationOnIcon className={classes.icon} />
                                {literals("contact_us.address")}
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <PersonInfo
                                        name={literals("contact_us.employees.managing_director.name")}
                                        title={literals("contact_us.employees.managing_director")}
                                        phone={literals("contact_us.employees.managing_director.phone")}
                                        email={literals("contact_us.employees.managing_director.email")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <PersonInfo
                                        name={literals("contact_us.employees.marketing.name")}
                                        title={literals("contact_us.employees.marketing")}
                                        phone={literals("contact_us.employees.marketing.phone")}
                                        email={literals("contact_us.employees.marketing.email")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <PersonInfo
                                        name={literals("contact_us.employees.general_inquiry.name")}
                                        title={literals("contact_us.employees.general_inquiry")}
                                        phone={literals("contact_us.employees.general_inquiry.phone")}
                                        email={literals("contact_us.employees.general_inquiry.email")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <PersonInfo
                                        name={literals("contact_us.employees.it.name")}
                                        title={literals("contact_us.employees.it")}
                                        phone={literals("contact_us.employees.it.phone")}
                                        email={literals("contact_us.employees.it.email")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </TopBorderContainer>
            </Container>
        </>
    );
};

type PersonInfoProps = {
    name: string;
    title: string;
    phone: string;
    email: string;
};

const PersonInfo = (props: PersonInfoProps) => {
    const getLiteral = useLiterals();
    return (
        <div style={{ borderBottom: "1px solid grey", padding: "20px 0" }}>
            <Typography variant="h6" style={{ paddingBottom: 10 }}>
                {props.name}
            </Typography>

            <p>{props.title}</p>
            <p>
                {getLiteral("person_info.phone")}: {props.phone}
            </p>
            <p>
                {getLiteral("person_info.email")}: {props.email}
            </p>
        </div>
    );
};

export { ContactUs };
