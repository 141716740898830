import _groupBy from "lodash/groupBy";
import _each from "lodash/each";
import _first from "lodash/first";

import {
    IPurchaseHistoryDetailsDTO,
    IAttendeeField,
    IError,
} from "@djordjeandjelkovic/medgress_common_react_modules";
import { HistoryAttendee } from "./Models";

export const processEmptyAttendees = (details: IPurchaseHistoryDetailsDTO[]) => {
    let index = 0;
    details.forEach((historyItem) => {
        if (historyItem.attendeeId === null) {
            historyItem.attendeeId = "deleted-" + index++;
        }
    });
};

export const createErrorAttendeeArray = (fields: IAttendeeField[], details: IPurchaseHistoryDetailsDTO[]) => {
    const attendees: HistoryAttendee[] = [];

    _each(
        _groupBy(details, (detail: IPurchaseHistoryDetailsDTO) => detail.attendeeId),
        (detailsGrouped) => {
            const attendeeInfo = _first(detailsGrouped);

            if (attendeeInfo == null) {
                return;
            }

            const attendeeFields: IError<IAttendeeField>[] = [];
            _each(fields, (field) => {
                const a = detailsGrouped.find((detail) => detail.fieldId === field.id);
                if (a === null) {
                    return;
                }

                field.value = a?.value;

                attendeeFields.push({
                    value: field,
                    error: false,
                });
            });

            attendees.push({
                ticketName: attendeeInfo.ticketName,
                eventName: attendeeInfo.eventName,
                ticketPrice: attendeeInfo.ticketPrice,
                ticketCurrency: attendeeInfo.ticketCurrency,
                attendee: {
                    id: { value: attendeeInfo.attendeeId, error: false },
                    firstName: { value: attendeeInfo.firstName, error: false },
                    lastName: { value: attendeeInfo.lastName, error: false },
                    email: { value: attendeeInfo.email, error: false },
                    fields: attendeeFields,
                    eventId: { value: attendeeInfo.eventId, error: false },
                },
            });
        }
    );

    return attendees;
};

export const formatTitle = (attendee: HistoryAttendee) => {
    return (
        `Ticket: ${attendee.ticketName} // ` +
        `Price: ${attendee.ticketPrice === 0 ? "Free" : attendee.ticketPrice} ` +
        `${attendee.ticketCurrency == null ? "" : attendee.ticketCurrency}`
    );
};
