import React from "react";

import { useHistory } from "react-router-dom";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { MenuItem } from "@material-ui/core";

import { useStyles } from "./styles";

import { authenticationService } from "$services/core";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { GoogleLogout } from "react-google-login";
import * as config from "$config/config";

interface ILogoutMenuItem {
    onClick?: () => void;
}

const LogoutMenuItem = (props: ILogoutMenuItem) => {
    const { onClick } = props;
    const classes = useStyles({});
    const history = useHistory();
    const getLiteral = useLiterals();

    const handleLogout = () => {
        authenticationService.logout();
        history.push("/");
        onClick && onClick();
        window.location.reload();
    };

    const handleFailedGoogleLogout = () => {
        console.error("Error while logout from Google");
    }

    if(authenticationService.isLoggedInByGoogle()) {
        return <GoogleLogout
            clientId={config.googleLogin.clientId!}
            buttonText={getLiteral("common.button.logout")}
            onLogoutSuccess={() => handleLogout()}
            onFailure={() => handleFailedGoogleLogout()}
            className={classes.fullWidth}
        />
    }

    return (
        <MenuItem onClick={handleLogout}>
            <ExitToAppIcon className={classes.icon} /> {getLiteral("common.button.logout")}
        </MenuItem>
    );
};

export { LogoutMenuItem };
