import React from "react";
import { Button, CardActions, CardContent, Grid, Typography } from "@material-ui/core";
import { PasswordField } from "$components/core";
import { useStyles } from "../styles";
import { useChangePasswordState } from "./useChangePasswordState";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { IUser } from "@djordjeandjelkovic/medgress_common_react_modules";

interface IProps {
    user: IUser | undefined;
}

const ChangePassword = (props: IProps) => {
    const classes = useStyles();
    const getLiteral = useLiterals();

    const [ { password, oldPassword, confirmPassword }, { handleChange, handleChangePassword }, ] = useChangePasswordState(props);

    return (
        <>
            <Typography variant="h4" className={classes.title}>
                {getLiteral("user_profile.menu.change_password")}
            </Typography>
            <CardContent className={classes.cardContent}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <PasswordField
                            name="old-password"
                            label={getLiteral("user_profile.user_details.form.change_password.old_password")}
                            onChange={handleChange}
                            value={oldPassword || ""}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordField
                            name="new-password"
                            label={getLiteral("user_profile.user_details.form.change_password.new_password")}
                            onChange={handleChange}
                            value={password || ""}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordField
                            name="confirm-password"
                            label={getLiteral(
                                "user_profile.user_details.form.change_password.confirm_password"
                            )}
                            onChange={handleChange}
                            value={confirmPassword || ""}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions className={classes.cardActions}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.updateButton}
                    onClick={handleChangePassword}>
                    {getLiteral("common.button.update")}
                </Button>
            </CardActions>
        </>
    );
};

export { ChangePassword };
