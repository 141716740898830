import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        descriptionContainer: {
            marginTop: 40,
            marginBottom: 40,
            textAlign: "justify",
        },
        descriptionContainerPadding: {
            paddingTop: 40,
            paddingBottom: 40,
            paddingLeft: 80,
            paddingRight: 80,
        },
        description: {
            marginTop: 10,
            marginBottom: 20,
        },
        findUs: {
            paddingBottom: 15,
        },
        contactPart: {
            padding: 20,
        },
        icon: {
            position: "relative",
            top: 5,
            marginRight: 5,
        },
    })
);
