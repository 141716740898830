import { useState } from "react";
import { useMessage } from "$helper/useMessage";
import { userService } from "$services/user.service";
import { ForgotPasswordProps } from "./ForgotPassword";
import { EventChange } from "@djordjeandjelkovic/medgress_common_react_modules";
import { useLiterals } from "$hooks/Translate/useLiterals";

const CLOSE_IN_SECONDS = 5;

export const useForgotPassword = (props: ForgotPasswordProps) => {
    const { onClose } = props;
    const { showSuccess, showError } = useMessage();
    const getLiteral = useLiterals();
    const [email, setEmail] = useState({ value: "", error: false, isLoading: false });

    const handleChange = (event: EventChange<string>) => {
        const {
            target: { value },
        } = event;
        setEmail((state) => ({ ...state, error: false, value }));
    };

    const handleResetClick = async () => {
        if (!email.value) {
            setEmail((old) => ({ ...old, error: true }));
            return;
        }
        setEmail((old) => ({ ...old, isLoading: true }));
        try {
            await userService.resetPassword({
                email: email.value,
                firstName: "",
                lastName: "",
                password: "",
                entityType: "",
            });
            showSuccess(getLiteral("sign-up.forgot_password.success"));
            setTimeout(() => {
                onClose();
            }, CLOSE_IN_SECONDS * 1000);
        } catch (error) {
            console.error(error);
            showError(getLiteral(`sign-up.forgot_password.${error}`));
        } finally {
            setEmail((old) => ({ ...old, isLoading: false }));
        }
    };

    return { email, onChange: handleChange, onResetClick: handleResetClick };
};
