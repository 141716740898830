import { Theme, makeStyles, createStyles } from "@material-ui/core";

type Props = {
    isClassicTheme?: boolean;
    mediaSmDown?: boolean;
};

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        navContainer: {
            height: "100%",
            alignItems: "center",
        },
        menuContainer: (props: Props) => ({
            textAlign: !props.mediaSmDown ? "center" : undefined,
        }),
        loginContainer: {
            textAlign: "right",
        },
        theme: (props: Props) => ({
            color: props.isClassicTheme ? theme.palette.secondary.main : theme.palette.primary.main,
        }),
        signIn: {
            marginRight: 5,
        },
        menu: {
            display: "inline-block",
        },
        sideMenuContainer: {
            textTransform: "uppercase",
            paddingTop: 0,
            paddingBottom: 0,
        },
        sideMenuLogo: {
            paddingLeft: 47,
        },
        sideMenuItem: {
            marginRight: 10,
        },
    })
);
