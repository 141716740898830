import React, { useEffect, useRef } from "react";
import _map from "lodash/map";
import _find from "lodash/find";
import { Grid } from "@material-ui/core";
import {
    IAttendee,
    FieldFactory,
    EventChange,
    TextField,
    IAttendeeField,
    ErrorType,
} from "@djordjeandjelkovic/medgress_common_react_modules";
import { Loader } from "$components/core";
import { useLiterals } from "$hooks/Translate/useLiterals";

type AttendeeProps = {
    attendee: ErrorType<IAttendee> | undefined;
    attendeeType?: number;
    onChange?: (event: EventChange<string>) => void;
    readOnly?: boolean;
    disabled?: boolean;
};

const Attendee = (props: AttendeeProps) => {
    const { attendee, attendeeType, onChange, readOnly, disabled } = props;
    const getLiteral = useLiterals();
    const firstNameInputRef = useRef<HTMLInputElement | null>(null);
    const firstNameRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        firstNameInputRef.current?.focus();
        window.scroll(0, findPos(firstNameRef.current) - 150);
    }, []);

    function findPos(obj: any): number {
        var curtop = 0;
        if (obj.offsetParent) {
            do {
                curtop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return curtop;
        }
        return 0;
    }

    if (!attendee) {
        return <Loader loading={true} />;
    }

    return (
        <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
                <TextField
                    name="firstName"
                    label={getLiteral("attendee.form.first_name")}
                    required={true}
                    constraints={{ maxLength: 100 }}
                    value={attendee.firstName.value}
                    onChange={!onChange ? () => {} : onChange}
                    fullWidth
                    error={attendee.firstName.error}
                    readOnly={readOnly}
                    disabled={disabled}
                    TextFieldProps={{
                        InputLabelProps: { shrink: true },
                        variant: "outlined",
                        inputProps: {
                            ref: firstNameInputRef,
                        },
                        ref: firstNameRef,
                    }}
                />
            </Grid>

            <Grid item md={6} xs={12}>
                <TextField
                    name="lastName"
                    label={getLiteral("attendee.form.last_name")}
                    required={true}
                    constraints={{ maxLength: 100 }}
                    value={attendee.lastName.value}
                    onChange={!onChange ? () => {} : onChange}
                    error={attendee.lastName.error}
                    fullWidth
                    readOnly={readOnly}
                    disabled={disabled}
                    TextFieldProps={{ InputLabelProps: { shrink: true }, variant: "outlined" }}
                />
            </Grid>

            <Grid item md={6} xs={12}>
                <TextField
                    name="email"
                    label={getLiteral("attendee.form.email_address")}
                    required={true}
                    constraints={{ maxLength: 100 }}
                    value={attendee.email.value}
                    onChange={!onChange ? () => {} : onChange}
                    error={attendee.email.error}
                    fullWidth
                    readOnly={readOnly}
                    disabled={disabled}
                    TextFieldProps={{ InputLabelProps: { shrink: true }, variant: "outlined" }}
                />
            </Grid>

            <Fields
                attendee={attendee}
                readOnly={readOnly}
                disabled={disabled}
                attendeeType={attendeeType}
                onChange={onChange}
            />
        </Grid>
    );
};

type FieldsProps = {
    attendee: ErrorType<IAttendee, "">;
    readOnly?: boolean;
    disabled?: boolean;
    attendeeType?: number;
    onChange?: (event: EventChange<string>) => void;
};
const Fields = ({ attendee, readOnly, disabled, attendeeType, onChange }: FieldsProps) => {
    return (
        <>
            {_map(attendee.fields, (attendeeField) => {
                if (!!attendeeType && !attendeeField.value.attendeeType.includes(String(attendeeType)))
                    return undefined;

                const df = getDependentFieldIfExists(attendee, attendeeField.value);

                return (
                    <Grid item md={6} xs={12} key={attendeeField.value.id}>
                        <FieldFactory
                            type={attendeeField.value.type}
                            name={attendeeField.value.id}
                            label={attendeeField.value.name}
                            required={attendeeField.value.required}
                            options={attendeeField.value.options}
                            value={attendeeField.value.value || ""}
                            dependField={df?.value}
                            readOnly={readOnly}
                            disabled={disabled}
                            error={attendeeField.error}
                            onChange={!onChange ? () => {} : onChange}
                            fullWidth={true}
                            {...innerProps(attendeeField.value.type)}
                        />
                    </Grid>
                );
            })}
        </>
    );
};

const innerProps = (type: string) => {
    if (["email", "text", "select_styled", "tickets"].includes(type)) {
        return { TextFieldProps: { InputLabelProps: { shrink: true }, variant: "outlined" as "outlined" } };
    }

    if (["phone"].includes(type)) {
        return {
            FormControlProps: { variant: "outlined" as "outlined" },
            TextFieldProps: { InputLabelProps: { shrink: true }, variant: "outlined" as "outlined" },
            DialCodeTextFieldProps: { InputLabelProps: { shrink: true }, variant: "outlined" as "outlined" },
        };
    }

    if (["select_native"].includes(type)) {
        return { FormControlProps: { variant: "outlined" as "outlined" } };
    }

    if (["date"].includes(type)) {
        return { DatePickerProps: { inputVariant: "outlined" as "outlined" } };
    }

    if (["datetime"].includes(type)) {
        return { DateTimePickerProps: { inputVariant: "outlined" as "outlined" } };
    }
};

const getDependentFieldIfExists = (
    attendeeFields: ErrorType<IAttendee, "">,
    attendeeField: IAttendeeField
) => {
    if (!attendeeField.dependOn) {
        return undefined;
    }
    return _find(attendeeFields.fields, (f) => f.value.id === attendeeField.dependOn!.id);
};

export { Attendee };
