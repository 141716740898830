import { useState, useEffect } from "react";
import { announcementService } from "$services/core";
import { Announcement } from "@djordjeandjelkovic/medgress_common_react_modules";
import { useMessage } from "$helper/useMessage";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { useStyles } from "./styles";

const useAnnouncementsState = () => {
    const [announcements, setAnnouncements] = useState<Announcement[]>([]);

    const classes = useStyles();
    const { showError } = useMessage();
    const getLiteral = useLiterals();

    useEffect(() => {
        const getData = (async () => {
            try {
                const response = await announcementService.list();
                setAnnouncements(response);
            } catch(error) {
                console.error(error);
                showError(getLiteral("common.error.general_error"));
            }
        });

        getData();
    }, [showError, getLiteral]);

    const clearAnnouncements = () => {
        announcements.forEach((announcement) => {
            sessionStorage.setItem("announcement-" + announcement.id, "true");
        });

        setAnnouncements([]);
    };

    return [{ announcements, classes }, { clearAnnouncements }];
};

export { useAnnouncementsState };
