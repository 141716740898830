import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => createStyles({
    uploadContainer: {
        width: "50%",
    },
    input: {
        display: "none",
    },
    inputButton: {
        position: "relative",
        left: "10px",
        bottom: "45px",
    },
    avatar: {
        height: "100px",
        width: "100px",
        position: "relative",
        display: "inline-flex",
        flexDirection: "column",
    },
    addPhoto: {
        cursor: "pointer",
    },
}));
