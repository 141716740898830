import React, { useState, useCallback } from "react";

import { TextField, TextFieldProps, InputAdornment, IconButton } from "@material-ui/core";

import VpnKeyIcon from "@material-ui/icons/VpnKey";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const PasswordField: React.FC<TextFieldProps> = (props) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = useCallback(() => {
        setShowPassword((state) => !state);
    }, []);

    return (
        <TextField
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <VpnKeyIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword}>
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            type={showPassword ? undefined : "password"}
            {...props}
        />
    );
};

export { PasswordField };
