export const SET_ID = "SET_ID" as "SET_ID";

export const CART = "CART" as "CART";

export type CartAction = {
    type: typeof SET_ID;
    altType: typeof CART;
    id: string | null;
    eventId: string;
};
