import React from "react";

import { initState, reducer } from "./reducer";
import * as SignUpActionCreators from "./actions";

import { bindActionCreators } from "$helper/bindActionCreators";

const useFormState = () => {
    const [state, dispatch] = React.useReducer(reducer, initState);

    const {
        setFirstName,
        setLastName,
        setEmail,
        setPhone,
        setPassword,
        setConfirmPassword,
        resetStateError,
        setFormValidationResult,
        reset,
    } = bindActionCreators(SignUpActionCreators, dispatch);

    return [
        state,
        {
            setFirstName,
            setLastName,
            setEmail,
            setPhone,
            setPassword,
            setConfirmPassword,
            resetStateError,
            setFormValidationResult,
            reset,
        },
    ];
};

export { useFormState };
