import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";

import Typography from "@material-ui/core/Typography";

import { ActivateWaiting } from "./ActivateWaiting";

import { signupService } from "$services/core";
import { useQueryParams } from "$hooks/QueryParams";

import { useStyles } from "./styles";
import { useLiterals } from "$hooks/Translate/useLiterals";

const ActivateAccount = () => {
    const [userIsActivated, setUserIsActivated] = useState(false);
    const [errorWhileActivating, setErrorWhileActivating] = useState(false);

    const classes = useStyles();
    const getLiteral = useLiterals();

    const query = useQueryParams(useLocation().search);
    const serializedUser = query.get("u");

    useEffect(() => {
        if(serializedUser === null || serializedUser.length < 1) {
            return;
        }

        const activate = async () => {
            try {
                await signupService.activateUser(serializedUser || "");
                setUserIsActivated(true);
            } catch(error) {
                setErrorWhileActivating(true);
                console.error(error);
            }
        }
        
        activate();
    }, [serializedUser, setUserIsActivated, setErrorWhileActivating]);

    if(errorWhileActivating) {
        return (
            <div className={classes.waitingWrapper}>
                <Typography className={classes.centerMiddle} variant="h4">
                    {getLiteral("common.error.invalid_request")}
                </Typography>
            </div>
        );
    }

    return userIsActivated ? <Redirect to={{ pathname: "/login" }} /> : <ActivateWaiting />;
};

export { ActivateAccount };
