import { useSelector } from "react-redux";
import { ReduxStore } from "$redux/reducers";
import { TicketsState } from "$redux/reducers/ticketsCartReducer";
import { TicketCart } from "$models/types";
import { createSelector } from "reselect";

const attendeesModelSelector = createSelector(
    (store: ReduxStore) => store.ticketsCart,
    (_: any, props: { eventId: string }): string => props.eventId,
    (ticketsCart: TicketsState, eventId: string): TicketCart[] => ticketsCart[eventId]?.tickets || []
);

export const useTicketCartSelector = (eventId: string | null) => {
    const ticketsInCart = useSelector<ReduxStore, TicketsState>((store) => store.ticketsCart);
    const cartId = useSelector<ReduxStore, string | null>((store) =>
        eventId == null ? null : store.ticketsCart[eventId]?.cartId || null
    );
    const eventsInCart = useSelector<ReduxStore, string[]>((store) => Object.keys(store.ticketsCart));

    const ticketsCart = useSelector<ReduxStore, TicketCart[]>((store) =>
        eventId === null ? [] : attendeesModelSelector(store, { eventId })
    );

    return { ticketsInCart, cartId, eventsInCart, ticketsCart };
};
