import * as React from "react";
import clsx from "clsx";

import { IconButton } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useStyles } from "./styles";

const ExpandButton: React.FC<{
    id: string;
    onClick: (id: string) => void;
    expand: boolean | undefined;
}> = ({ id, onClick, expand }) => {
    const classes = useStyles();
    return (
        <IconButton
            className={clsx(classes.expand, { [classes.expandOpen]: expand })}
            onClick={(e) => onClick(id)}
        >
            <ExpandMoreIcon />
        </IconButton>
    );
};

export { ExpandButton };
