import _map from "lodash/map";
import { Option } from "./Option";
import { IOption } from "./IOption";
import { OptionDto } from "./OptionDto";

export function optionMapper(data: OptionDto): IOption[] {
    if (!data) {
        return [];
    }

    return _map(JSON.parse(data.values), (item) => new Option(item.name, item.key, item.alias));
}
