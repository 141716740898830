import { DELETE_TIMEOUT, INIT, SET_CART_TIMEOUT, SET_CART_ID_INIT } from "./cartTimeoutActionsTypes";

export const decrementCartTimeout = () => ({
    type: SET_CART_TIMEOUT,
});

export const deleteTimeout = (eventId: string) => ({
    type: DELETE_TIMEOUT,
    eventId,
});

export const initTimeout = (eventId: string) => ({
    type: INIT,
    eventId,
});

export const setCartIdForTimeout = (eventId: string, cartId: string) => ({
    type: SET_CART_ID_INIT,
    eventId,
    cartId,
});
