import React from "react";
import { Button, Divider, Grid, Typography } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import PinDropIcon from "@material-ui/icons/PinDrop";
import LabelIcon from "@material-ui/icons/Label";
import { EventDTO, EventLocationTypeEnum, EventQRCodeDTO } from "@djordjeandjelkovic/medgress_common_react_modules";
import { useStyles } from "./styles";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { TopBorderContainer } from "$components/core";
import HideElement from "$components/HideElement/HideElement";
import { getLocationInfo } from "src/utilities/eventUtils";

type EventInformationProps = {
    details: EventDTO;
    eventQRCode: EventQRCodeDTO | null;
};

const fontSizeStyle = { fontSize: 30 };

const EventInformation = (props: EventInformationProps) => {
    const { details, eventQRCode } = props;
    const classes = useStyles();

    return (
        <TopBorderContainer className={classes.paper}>
            <Grid container>
                <Grid item md={5}>
                    <EventShortInformation details={details} eventQRCode={eventQRCode} />
                </Grid>
                <Grid item md={7}>
                    <Description
                        text={details.description}
                        eventName={details.name}
                        eventLocation={details.location}
                        eventCity={details.city}
                        agenda={details.agendaPath}
                    />
                </Grid>
            </Grid>
        </TopBorderContainer>
    );
};

const EventShortInformation = (props: EventInformationProps) => {
    const { details, eventQRCode } = props;
    const classes = useStyles();
    const getLiteral = useLiterals();

    return (
        <>
            {details.icon && (
                <Grid item sm={12} className={classes.eventItem}>
                    <img src={details.icon} alt="event logo" />
                </Grid>
            )}

            <Grid item xs={12} className={classes.eventItem}>
                <Card
                    title={getLiteral("event_details.info.start_date")}
                    text={details.startDate.toDateString()}
                    icon={<DateRangeIcon style={fontSizeStyle} color="inherit" />}
                />
            </Grid>
            <Grid item xs={12} className={classes.eventItem}>
                <Card
                    title={getLiteral("event_details.info.end_date")}
                    text={details.endDate.toDateString()}
                    icon={<DateRangeIcon style={fontSizeStyle} color="inherit" />}
                />
            </Grid>
            
            <HideElement inCase={details.natureType === EventLocationTypeEnum.VIRTUAL}>
                <Grid item xs={12} className={classes.eventItem}>
                    <Card
                        title={getLiteral("event_details.info.location")}
                        text={getLocationInfo(details.location, details.city, details.countryId)}
                        icon={<PinDropIcon style={fontSizeStyle} color="inherit" />}
                    />
                </Grid>

                <Grid item xs={12} className={classes.eventItem}>
                    <Card
                        title={getLiteral("event_details.info.venue")}
                        text={details.venue}
                        icon={<PinDropIcon style={fontSizeStyle} color="inherit" />}
                    />
                </Grid>
            </HideElement>
            
            <Grid item xs={12} className={classes.eventItem}>
                <Card
                    title={getLiteral("event_details.info.event_type")}
                    text={getLiteral(`events.event_type.${details.eventType}`)}
                    icon={<LabelIcon style={fontSizeStyle} color="inherit" />}
                />
            </Grid>
            
            <HideElement inCase={eventQRCode === null}>
                <Grid item xs={12} className={classes.eventItem}>
                    <img src={eventQRCode?.qrCode} alt="event-qr-code" width={150} height={150} />
                </Grid>
            </HideElement>
        </>
    );
};

type DescriptionProps = {
    eventName: string;
    eventLocation: string;
    eventCity: string;
    text: string;
    agenda?: string | null;
};

const Description = (props: DescriptionProps) => {
    const { eventName, eventLocation, eventCity, text, agenda } = props;
    const classes = useStyles();

    return (
        <>
            <Typography variant="h4" className={classes.eventName}>
                {eventName}
            </Typography>
            <Typography variant="h6">{getLocationInfo(eventLocation, eventCity)}</Typography>
            <Divider />
            <Typography
                variant="body1"
                className={classes.description}
                dangerouslySetInnerHTML={{
                    __html: text.replaceAll("\n", "<br />"),
                }}></Typography>
            {agenda && (
                <Button color="primary" variant="contained" href={agenda} target="_blank">
                    download agenda
                </Button>
            )}
        </>
    );
};

type CardProps = {
    icon: React.ReactNode;
    title: string;
    component?: React.ReactNode;
    text?: string;
};

const Card = (props: CardProps) => {
    const { icon, title, component, text } = props;
    const getLiteral = useLiterals();
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item xs={2} className={classes.icon}>
                {icon}
            </Grid>
            <Grid item xs={10} container>
                <Grid item xs={12}>
                    <Typography variant="subtitle2">{title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {!component ? (
                        <Typography variant="subtitle1" className={classes.labelValue}>
                            {text || getLiteral("n_a")}
                        </Typography>
                    ) : (
                        <>{component}</>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export { EventInformation };
