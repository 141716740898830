import { useState } from "react";

const useEventsListState = () => {
    const [page, setPage] = useState(1);

    const handleChangePage = (): void => {
        setPage((page) => page + 1);
    };

    const onEventClick = (acronym: string): void => {
        window.location.href = `/events/${acronym}`;
    }

    return [{
        page,
    }, {
        onEventClick, handleChangePage,
    }];
}

export default useEventsListState;
