import { useEffect } from "react";
import * as CartActionCreators from "$redux/actions/CartTimeout/cartTimeoutActions";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useCartTimeoutSelector } from "$redux/selectors/useCartTimeoutSelector";
import { CartTimeoutState } from "$redux/reducers/cartTimeoutReducer";
import _find from "lodash/find";
import _keys from "lodash/keys";
import { LocalStorage } from "$constants/LocalStorage";
import * as TicketsCartActionCreators from "$redux/actions/TicketsCart/ticketsCartActions";
import ticketCartService from "$services/ticketcart.service";
import { useTicketCartSelector } from "$redux/selectors";

export const useCartTimeout = () => {
    const { decrementCartTimeout, deleteElapsed } = useCartActionCreators();
    const { cartTimeout } = useCartTimeoutSelector();
    const { ticketsInCart } = useTicketCartSelector(null);

    useEffect(() => {
        deleteCartIfElapsed(cartTimeout, deleteElapsed);

        const interval = setInterval(() => {
            if (!_keys(ticketsInCart).length) {
                return;
            }

            decrementCartTimeout();
        }, 1000);

        return () => clearInterval(interval);
    });

    return null;
};

const useCartActionCreators = () => {
    const dispatch = useDispatch();
    const { decrementCartTimeout, deleteTimeout } = bindActionCreators(CartActionCreators, dispatch);
    const { removeAllTickets } = bindActionCreators(TicketsCartActionCreators, dispatch);

    return {
        decrementCartTimeout,
        deleteElapsed: async (eventId: string, cartId: string) => {
            deleteTimeout(eventId);
            removeAllTickets(eventId);
            removeCurrentStepFromLocalStorage();
            try {
                await ticketCartService.deleteCart(cartId);
            } catch (error) {
                console.error(error);
            }
        },
    };
};

const deleteCartIfElapsed = (
    cartTimeout: CartTimeoutState,
    deleteItem: (eventId: string, cartId: string) => void
) => {
    const eventFinishedTimeout = _find(_keys(cartTimeout), (key) => cartTimeout[key].timeout <= 0);
    eventFinishedTimeout && deleteItem(eventFinishedTimeout, cartTimeout[eventFinishedTimeout].cartId!);
};

const removeCurrentStepFromLocalStorage = () => {
    localStorage.removeItem(LocalStorage.CURRENT_STEP);
};
