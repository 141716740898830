import { SET_IS_CLASSIC_THEME, ThemeAction } from "$redux/actions";

const InitialState = {
    isClassicTheme: true
};

export const themeReducer = (state = InitialState, action: ThemeAction) => {
    switch (action.type) {
        case SET_IS_CLASSIC_THEME:
            return {
                ...state,
                isClassicTheme: action.isClassicTheme,
            };
        default:
            return state;
    }
}
