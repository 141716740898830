import React from "react";
import { GoogleMaps, TopBorderContainer } from "$components/core";
import { Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { useLiterals } from "$hooks/Translate/useLiterals";

type FindUsProps = {
    longitude: number;
    latitude: number;
    isMarkerShown: boolean;
};

const FindUs = (props: FindUsProps) => {
    const { longitude, latitude, isMarkerShown } = props;
    const classes = useStyles();
    const getLiteral = useLiterals();

    return (
        <TopBorderContainer>
            <Typography variant="h6" className={classes.title}>
                {getLiteral("event_details.location.title")}
            </Typography>
            <GoogleMaps longitude={longitude} latitude={latitude} isMarkerShown={isMarkerShown} />
        </TopBorderContainer>
    );
};

export { FindUs };
