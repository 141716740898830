import { useState, useEffect } from "react";
import eventsService from "$services/events.service";
import { EventDTO, EventQRCodeDTO } from "@djordjeandjelkovic/medgress_common_react_modules";

type EventDetailsProps = {
    acronym: string;
};

const useEventDetailsState = (props: EventDetailsProps) => {
    const { acronym } = props;
    const [event, setEvent] = useState<EventDTO | null>(null);
    const [eventQRCode, setEventQRCode] = useState<EventQRCodeDTO | null>(null);

    useEffect(() => {
        const getData = async () => {
            const eventDetails = await eventsService.getByAcronym(acronym);
            setEvent(eventDetails);

            const eventQRCodeResponse = await eventsService.getQRCode(eventDetails.id);
            setEventQRCode(eventQRCodeResponse);
        };

        getData();
    }, [acronym]);

    const location = {
        latitude: event && event.coordinates !== null ? parseFloat(event.coordinates.split(";")[0]) : 0,
        longitude: event && event.coordinates !== null ? parseFloat(event.coordinates.split(";")[1]) : 0,
        isMarkerShown: event && event.coordinates !== null,
    };

    return { event, eventQRCode, location };
};

export { useEventDetailsState };
