const removeSpaces = (str: string) => {
    if (!str) return "";

    let spaceIndex = str.indexOf(" ");
    while (spaceIndex >= 0) {
        str = removeCharByIndex(str, spaceIndex);
        spaceIndex = str.indexOf(" ");
    }

    return str;
}

const removeCharByIndex = (str: string, index: number) => {
    if (str.length === 1) return "";
    else if (index === 0) {
        return str.substr(1, str.length);
    } else {
        return str.substring(0, index) + str.substr(index + 1, str.length);
    }
}

const resizeImage = (element: HTMLInputElement, callback: (url: string) => void, maxResolution?: number): void => {
    if (!element || !element.files || element.files.length === 0) {
        return;
    }

    const file: File = element.files[0];
    const defaultMaxResolution = 200;

    if (file) {
        var reader = new FileReader();

        // Set the image once loaded into file reader
        reader.onloadend = function (e: ProgressEvent<FileReader>) {
            var image = new Image();

            image.onload = function (imageEvent) {
                var max_size = !maxResolution ? defaultMaxResolution : maxResolution;
                var w = image.width;
                var h = image.height;

                if (w > h) {
                    if (w > max_size) {
                        h *= max_size / w;
                        w = max_size;
                    }
                } else {
                    if (h > max_size) {
                        w *= max_size / h;
                        h = max_size;
                    }
                }

                var canvas = document.createElement("canvas");
                canvas.width = w;
                canvas.height = h;
                canvas?.getContext("2d")?.drawImage(image, 0, 0, w, h);

                let dataURL;
                if (file.type === "image/jpeg") {
                    dataURL = canvas.toDataURL("image/jpeg", 1.0);
                } else {
                    dataURL = canvas.toDataURL("image/png");
                }

                callback(dataURL);
            };

            image.src = (e.target?.result as string) || "";
        };

        reader.readAsDataURL(file);
    }
}

const toStringDate = (date: Date | null): string => {
    if (!date) {
        return "";
    }
    return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
}

const endWithThreeDot = (value: string, maxLength: number) => {
    if (value.length >= maxLength) {
        return value.substring(0, maxLength) + "...";
    }

    return value;
};

const getStringTime = (secs: number) => {
    var hours = Math.floor(secs / 3600);
    var minutes = Math.floor(secs / 60) % 60;
    var seconds = secs % 60;

    return [hours, minutes, seconds]
        .map((h) => (h < 10 ? "0" + h : h))
        .filter((h, i) => h !== "00" || i > 0)
        .join(":");
};

const forEachAsync = async (
    array: any[],
    callback: (item: any, index?: number) => Promise<void>
): Promise<void> => {
    if (!array) return;

    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index);
    }
};

const dateNow = () => new Date();

const dateFromString = (date: string) => new Date(date);

export const any = (arr: any[] | null | undefined) => {
    return !(arr === null || typeof arr === "undefined" || arr.length === 0);
};

export const utils = {
    removeSpaces,
    removeCharByIndex,
    resizeImage,
    toStringDate,
    endWithThreeDot,
    getStringTime,
    forEachAsync,
    dateNow,
    dateFromString,
    any,
};

export default utils;
