import { authHeader, handleResponse } from ".";
import * as config from "../config";

async function request<T>(url: string, methodType: string, data: any): Promise<T> {
    const requestOptions = getRequestOptions(methodType, data);

    const urlPath = joinAbsoluteUrlPath(config.engine.apiUrl!, url);
    return fetch(urlPath, requestOptions).then(handleResponse);
}

export async function send<D extends {}, T>(url: string, methodType: string, data: D): Promise<T> {
    const requestOptions = {
        method: methodType,
        headers: { "Content-Type": "application/json", "X-Application": "EVENTS" },
        body: JSON.stringify(data),
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function getRequestOptions(methodType: string, data: any) {
    if (methodType === "GET") {
        return {
            method: methodType,
            headers: {
                ...authHeader(),
                "Content-Type": "application/json",
                "X-Application": "EVENTS",
            },
        };
    }

    if (methodType === "POST" || methodType === "PUT" || methodType === "DELETE") {
        return {
            method: methodType,
            headers: {
                ...authHeader(),
                "Content-Type": "application/json",
                "X-Application": "EVENTS",
            },
            body: JSON.stringify(data),
        };
    }
}

export async function get<T>(url: string): Promise<T> {
    return request(url, "GET", {});
}

export async function post<D extends {}, T>(url: string, data: D): Promise<T> {
    return request(url, "POST", data);
}

export async function put<D extends {}, T>(url: string, data: D): Promise<T> {
    return request(url, "PUT", data);
}

export async function remove<T>(url: string): Promise<T> {
    return request(url, "DELETE", {});
}

export function joinAbsoluteUrlPath(...args: string[]) {
    return args.map((pathPart) => pathPart.replace(/(^\/|\/$)/g, "")).join("/");
}

export const engineUrl = () => config.engine.apiUrl;
