import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            padding: 25,
        },
        title: {
            textAlign: "center",
        },
        logo: {
            position: "absolute",
            top: 15,
            left: 15,
        },
        recaptcha: {
            display: "inline-block",
        },
        recaptchaGridItem: {
            textAlign: "center",
        },
        dialogTitle: {
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        },
        signUpOr: {
            position: "relative",
            top: "-15px",
            backgroundColor: theme.palette.primary.contrastText,
            display: "inline-block",
            width: "40px",
            textAlign: "center",
            left: "50%",
            marginLeft: "-20px",
            color: "grey",
        },
        signInGoogleButton: {
            textAlign: "center",
            margin: 10,
        }
    })
);
