import React, { useState } from "react";
import { Button, CardActions, CardContent, Grid, InputAdornment, Typography } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import WorkIcon from "@material-ui/icons/Work";
import EmailIcon from "@material-ui/icons/Email";
import CallIcon from "@material-ui/icons/Call";
import { useStyles } from "../styles";
import { Company, EventChange, IUser, TextField, PhoneField } from "@djordjeandjelkovic/medgress_common_react_modules";
import { useLiterals } from "$hooks/Translate/useLiterals";

type Props = {
    user: IUser;
    handleUpdateClick: (userUpdate: IUser) => void;
};

const UserDetails = (props: Props) => {
    const classes = useStyles();
    const getLiteral = useLiterals();

    const { user, handleUpdateClick } = props;

    const [firstName, setFirstName] = useState<string>(user.firstName);
    const [lastName, setLastName] = useState<string>(user.lastName);
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>(user.phoneNumber);

    const handleChange = (event: EventChange<string> | EventChange<Company>) => {
        const { target: { name, value }, } = event;

        switch (name) {
            case "firstName":
                setFirstName((value as string).substr(0, 100));
                break;
            case "lastName":
                setLastName((value as string).substr(0, 100));
                break;
            case "phonenumber":
                setPhoneNumber((value as string).substr(0, 20));
                break;
        }
    };

    const mapToUser = (): IUser => {
        const userUpdate = {...user};
        userUpdate.firstName = firstName || "";
        userUpdate.lastName = lastName || "";
        userUpdate.phoneNumber = phoneNumber || "";

        return userUpdate;
    }

    const customProps = React.useMemo(
        () => ({
            firstName: {
                fullWidth: true,
                TextFieldProps: {
                    variant: "outlined" as "outlined",
                    InputProps: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonIcon />
                            </InputAdornment>
                        ),
                    },
                },
            },
            lastName: {
                fullWidth: true,
                TextFieldProps: {
                    variant: "outlined" as "outlined",
                    InputProps: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonIcon />
                            </InputAdornment>
                        ),
                    },
                },
            },
            company: {
                TextFieldProps: {
                    fullWidth: true,
                    variant: "outlined" as "outlined",
                    InputProps: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <WorkIcon />
                            </InputAdornment>
                        ),
                    },
                },
            },
            email: {
                fullWidth: true,
                TextFieldProps: {
                    variant: "outlined" as "outlined",
                    InputProps: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                        ),
                    },
                },
                disabled: true,
            },
            phone: {
                fullWidth: true,
                TextFieldProps: {
                    variant: "outlined" as "outlined",
                    InputProps: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <CallIcon />
                            </InputAdornment>
                        ),
                    },
                },
            },
            submitButton: {
                ButtonProps: {
                    color: "primary" as "primary",
                    variant: "contained" as "contained",
                    fullWidth: true,
                },
            },
        }),
        []
    );

    return (
        <>
            <Typography variant="h4" className={classes.title}>
                {getLiteral("user_profile.menu.user_details")}
            </Typography>
            <CardContent className={classes.cardContent}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            name="firstName"
                            label={getLiteral("user_profile.user_details.form.user_profile.first_name")}
                            onChange={handleChange}
                            value={firstName || ""}
                            {...customProps.firstName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="lastName"
                            label={getLiteral("user_profile.user_details.form.user_profile.last_name")}
                            onChange={handleChange}
                            value={lastName || ""}
                            {...customProps.lastName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="email"
                            label={getLiteral("user_profile.user_details.form.user_profile.email")}
                            onChange={handleChange}
                            value={user?.email || ""}
                            {...customProps.email}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PhoneField
                            name="phonenumber"
                            label={getLiteral("user_profile.user_details.form.user_profile.phone")}
                            onChange={handleChange}
                            value={phoneNumber || ""}
                            {...customProps.phone}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions className={classes.cardActions}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.updateButton}
                    onClick={() => handleUpdateClick(mapToUser())}>
                    {getLiteral("common.button.update")}
                </Button>
            </CardActions>
        </>
    );
};

export { UserDetails };
