const images = {
    arrowUp: require("./img/arrow-up.png"),
    calendar: require("./img/calendar.png"),
    defaultEventLogo: require("./img/default-event-logo.png"),
    defaultEventCover: require("./img/default-event-cover.jpg"),
    eventsCover: require("./img/events-cover.jpg"),
    line: require("./img/line.png"),
    logo: require("./img/logo.png"),
    pageHeader: require("./img/page-header.jpg"),
    popularEvents: require("./img/popular-events.jpg"),
    shape: require("./img/shape.png"),
    tickets: require("./img/tickets.png"),
    tickets1: require("./img/tickets.jpg"),
    creditCard: require("./img/credit-card.png"),
    paypal: require("./img/paypal.png"),
};

export default images;
