import { IPayment } from "$redux/reducers/paymentReducers";
import ticketCartService from "$services/ticketcart.service";
import { SendTicketsTo } from "@djordjeandjelkovic/medgress_common_react_modules";

export interface IPaymentService {
    pay: (cartId: string, paymentInfo: IPayment, sendTicketsTo: SendTicketsTo) => Promise<{}>;
}

export const usePaymentHandler = (handlerName: string): IPaymentService | null => {
    switch (handlerName) {
        case "stripe":
            return {
                pay: (cartId, paymentInfo, sendTicketsTo) =>
                    ticketCartService.stripePayment(cartId, paymentInfo, sendTicketsTo),
            };
        case "paypal":
            return {
                pay: (cartId, paymentInfo, sendTicketsTo) =>
                    ticketCartService.payPalPayment(cartId, paymentInfo),
            };
        case "payfort":
            return {
                pay: (cartId, paymentInfo, sendTicketsTo) => {
                    return Promise.reject("Not implemented");
                },
            };
        default:
            return null;
    }
};
