import * as React from "react";
import { Grid, Typography } from "@material-ui/core";

import { useStyles } from "./styles";
import { LoginForm, TopBorderContainer } from "$components/core";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { authenticationService } from "$services/core";
import { Redirect } from "react-router-dom";

const Login = () => {
    const classes = useStyles();
    const literals = useLiterals();
    const user = authenticationService.getUser();
    if (user !== null) {
        return (<Redirect to={"/"} />);
    }

    return (
        <Grid container>
            <Grid item md={4} sm={2} xs={1}></Grid>
            <Grid item md={4} sm={8} xs={10}>
                <TopBorderContainer className={classes.loginWrapper}>
                    <Typography variant="h4" className={classes.title}>
                        {literals("sign_in.title")}
                    </Typography>
                    <LoginForm useRedirect={true} />
                </TopBorderContainer>
            </Grid>
            <Grid item md={4} sm={2} xs={1}></Grid>
        </Grid>
    );
};

export { Login };
