import React from "react";
import { format } from "date-fns";
import _take from "lodash/take";
import _map from "lodash/map";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { EventDTO } from "@djordjeandjelkovic/medgress_common_react_modules";

import { EventItemsContainerLoader } from "$components/core";
import { DateDefaultFormat } from "$constants/Dates";
import { useLiterals } from "$hooks/Translate/useLiterals";
import utils from "$helper/utils";

import useEventsListState from "./useEventsListState";
import useStyles from "./styles";

interface EventsListProps {
    list: EventDTO[] | undefined;
}

const EVENTS_PER_PAGE = 6;

const EventsList = (props: EventsListProps) => {
    const { list } = props;
    const classes = useStyles();
    const getLiteral = useLiterals();

    const [{
        page,
    }, {
        onEventClick, handleChangePage
    }] = useEventsListState();

    if (!list) {
        return <EventItemsContainerLoader />;
    }

    if (list.length === 0) {
        return <h1 className={classes.noEvents}>{getLiteral("events.event_list.no_events")}</h1>;
    }

    return (
        <Container className={classes.root}>
            <Grid container spacing={4}>
                {_map(_take(list, page! * EVENTS_PER_PAGE), (eventDetails) => {
                    return (
                        <Grid
                            item lg={4} md={6} xs={12}
                            key={`paper-${eventDetails.acronym}`}
                            onClick={() => onEventClick?.(eventDetails.acronym)}>
                            <Paper square={true} className={classes.listItemContainer}>
                                <Grid
                                    item
                                    lg={12}
                                    className={classes.eventItemImage}
                                    style={{ backgroundImage: `url(${eventDetails.coverImageSmaller})` }}>
                                </Grid>
                                <Grid item lg={12} className={classes.eventItemDetailsRoot}>
                                    <Grid className={classes.eventItemDetailsContainer}>
                                        <Grid item lg={12}>
                                            <Typography
                                                variant="h6"
                                                align="right">
                                                {getLiteral(`events.event_type.${eventDetails.eventType}`)}
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={12}>
                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                className={classes.eventItemName}>
                                                {utils.endWithThreeDot(eventDetails.name, 40)}
                                            </Typography>
                                            <Typography
                                                variant="h6">
                                                {`${format(eventDetails.startDate, DateDefaultFormat)} ${eventDetails.venue ? ", " + eventDetails.venue : ''}`}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Typography
                                        variant="h6">
                                        {getLiteral("events.event_list.view_event")}
                                    </Typography>

                                    <Grid container>
                                        <Button
                                            href={"/events/" + eventDetails.acronym}>
                                            
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    );
                })}
            </Grid>

            <Grid className={classes.loadMoreContainer}>
                {list.length > ( page! * EVENTS_PER_PAGE) && (
                    <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        className={classes.loadMore}
                        onClick={handleChangePage}>
                        {getLiteral("events.event_list.load_more")}
                    </Button>
                )}
            </Grid>
            
        </Container>
    );
};

export default EventsList;
