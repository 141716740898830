import React from "react";
import { TopBorderContainer } from "$components/TopBorderContainer";
import { useCartTimeoutSelector } from "$redux/selectors/useCartTimeoutSelector";
import { Typography } from "@material-ui/core";
import utils from "$helper/utils";
import { useStyles } from "./style";

type ElapsedCartTimeProps = {
    eventId?: string;
};
export const ElapsedCartTime = ({ eventId }: ElapsedCartTimeProps) => {
    const { cartTimeout } = useCartTimeoutSelector();
    const classes = useStyles();

    if (!eventId || !cartTimeout[eventId]) return null;

    return (
        <TopBorderContainer className={classes.container}>
            <Typography variant="h5">
                Reserved for: {utils.getStringTime(cartTimeout[eventId].timeout)}
            </Typography>
        </TopBorderContainer>
    );
};
