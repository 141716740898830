import eventsService from "$services/events.service";
import { EventDTO } from "@djordjeandjelkovic/medgress_common_react_modules";
import { useMessage } from "$helper/useMessage";
import { useLiterals } from "$hooks/Translate/useLiterals";

export const useFetchEventDetails = (
    setEventDetails: React.Dispatch<React.SetStateAction<EventDTO | undefined>>
) => {
    const { showError } = useMessage();
    const getLiteral = useLiterals();

    return {
        fetch: async (eventId: string) => {
            try {
                const eventDetails = await eventsService.getById(eventId);
                setEventDetails(eventDetails);
            } catch (error) {
                showError(getLiteral("checkout.event_details.error_message"));
                console.error(error);
            }
        },
    };
};
