import { useLiteralSelectors } from "$redux/selectors";
import { useMemo } from "react";
import { createLiterals } from "../../utilities/locale/i18n";

export const useLiterals = () => {
    const literals = useLiteralSelectors().literals;
    const getLiterals = useMemo(() => createLiterals(literals), [literals]);

    return getLiterals;
};
